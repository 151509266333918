import { NavLink } from 'react-router-dom'
import ReactFlipCard from 'reactjs-flip-card'
import PositionsSubscribed from '../../../interfaces/PositionsSubscribed'
import { ChevronRightIcon } from '@heroicons/react/outline'

interface Props {
    image?: string,
    title?: string,
    content?: string
    vacancie?: PositionsSubscribed
}

export const FlipCard = ({ image, title, content, vacancie }: Props) => {
    return (
        <ReactFlipCard
            direction='vertical'
            flipTrigger='onClick'
            containerStyle={{ width: '100%', height: '100%' }}
            frontComponent={
                <div key={title}>
                    {
                        vacancie ?
                        <img className="w-full aspect-5/6" src={`${process.env.REACT_APP_API_URL!.replaceAll('/v1', '')}/${vacancie.image}`} alt="" />
                        :
                        <img className="w-full aspect-5/6" src={image} alt="" />
                    }
                    <div className='flex'>
                        <span className=" text-white text-2xl font-bold flex items-center justify-center absolute bottom-4 right-20 text-right">{title}</span>
                        <button className="bg-dkt-brand-blue text-white w-14 h-14 flex items-center justify-center rounded-full absolute bottom-4 right-4 shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">
                            +
                        </button>
                    </div>
                </div>
            }
            backComponent={
                vacancie ?
                    < article className="pt-12 pb-6 px-6 flex max-w-xl flex-col items-start justify-between aspect-5/6" >
                        <div className="group relative">
                            <div >
                                <h3 className="mt-6 text-xl font-bold leading-8 text-dkt-brand-blue pb-4">{vacancie.title}</h3>
                                <dl>
                                    <ChevronRightIcon className="absolute h-5 w-5 text-dkt-green-01" aria-hidden="true" />
                                    <dd className="mt-2 ml-9 text-sm">Ubicaciones: {vacancie.places}</dd>
                                </dl>
                                <dl>
                                    <ChevronRightIcon className="absolute h-5 w-5 text-dkt-green-01" aria-hidden="true" />
                                    <dd className="mt-2 ml-9 text-sm">Equipo: {vacancie.team}</dd>
                                </dl>
                                <dl>
                                    <ChevronRightIcon className="absolute h-5 w-5 text-dkt-green-01" aria-hidden="true" />
                                    <dd className="mt-2 ml-9 text-sm">Deportes: {vacancie.sports}</dd>
                                </dl>
                            </div>
                        </div>
                        {
                            vacancie &&
                            <div className="sm:flex justify-center mx-auto w-full">
                                <div className="text-sm w-full">
                                    <NavLink className="relative flex items-center text-left w-full text-dkt-brand-blue rounded-full px-4 py-1.5 ring-1 ring-dkt-brand-blue hover:bg-dkt-brand-blue hover:text-white"
                                        to={vacancie.id}>
                                        <span>{title}</span>
                                        <span aria-hidden="true">&rarr;</span>
                                    </NavLink>
                                </div>
                            </div>
                        }
                        <button className="bg-dkt-brand-blue text-white w-14 h-14 flex items-center justify-center rounded-full absolute top-4 right-4 shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">
                            x
                        </button>
                    </article >
                    :
                    < article className="pt-12 pb-6 px-6 flex max-w-xl flex-col items-start justify-between aspect-5/6" >
                        <div className="group relative">
                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                <div>
                                    <span className="text-xl absolute inset-0" />
                                    {title}
                                </div>
                            </h3>
                            <p className="mt-4 pt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                                {content}
                            </p>
                        </div>
                        <button className="bg-dkt-brand-blue text-white w-14 h-14 flex items-center justify-center rounded-full absolute top-4 right-4 shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">
                            x
                        </button>
                    </article >
            }
        />
    )
}