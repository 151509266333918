import { TrashIcon, DocumentIcon } from "@heroicons/react/solid"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ErrorMessage, Form, Formik } from "formik"
import toast from "react-hot-toast";
import * as Yup from 'yup'
import useSession from "../../../../hooks/useSession";
import { createDocumentApplicant, filesApplicant, removeDocumentApplicant } from "../../../../selectors/applicants";
import { LoadingSpinner } from "../../../../components/ui/LoadingSpinner";
import { Alerts } from "../../../../components/ui/Alerts";

interface Props {
  setSelectedMenuItem?: any;
}

export const Documents = ({ setSelectedMenuItem }: Props) => {

  const queryClient = useQueryClient();
  const { currentUser } = useSession()
  const { data: getDocumentsApplicant, isLoading: isLoadingDocumentsApplicant, isError: isErrorDocumentsApplicant } = useQuery(["DocumentsApplicant"], () => filesApplicant(currentUser?.id))

  const { mutate: addDocumentToApplicant } = useMutation(createDocumentApplicant, {
    onSuccess: (newDocument) => {
      getDocumentsApplicant?.push(newDocument)
      toast.dismiss()
      toast.success("Has agregado un nuevo documento.", { style: { background: "#3643BA", color: "white" } })
    },
    onError: (error) => {
      console.log(error)
      toast.dismiss()
      toast.error("No fue posible agregar tu nuevo documento, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
    },
    onMutate: () => {
      toast.loading("Cargando un documento.", { style: { background: "#7AFFA6", color: "white" } })
    }
  })

  const { mutate: deleteDocumentToApplicant } = useMutation(removeDocumentApplicant, {
    onSuccess: () => {
      queryClient.invalidateQueries(["DocumentsApplicant"])
      toast.dismiss()
      toast.success("Has eliminado un documento.", { style: { background: "#3643BA", color: "white" } })
    },
    onError: (error) => {
      console.log(error)
      toast.dismiss()
      toast.error("No fue posible eliminar tu documento, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
    },
    onMutate: () => {
      toast.loading("Eliminando un documento.", { style: { background: "#7AFFA6", color: "white" } })
    }
  })

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          idApplicant: currentUser ? currentUser.id : "",
          file: ""
        }}
        onSubmit={
          (values, { resetForm }) => {
            const dat = new FormData();
            dat.set('file', values.file);
            addDocumentToApplicant({ idApplicant: values.idApplicant, file: dat })
            resetForm()
          }
        }
        validationSchema={
          Yup.object().shape({
            file: Yup.mixed()
              .required('El archivo es requerido'),
          })
        }
      >
        {
          ({ setFieldValue }) => (
            <Form className="space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto max-w-7xl mt-16">
              <div className="divide-y divide-grey-dkt-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                  <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">DOCUMENTOS</h3>
                  <p className="mt-1 text-lg">Carga tu hoja de vida, asi los reclutadores podrán saber más sobre tu perfil profesional y personal</p>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                    <label htmlFor="file" className="block text-lg font-medium">
                      Carga tus documentos
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="file"
                        id="file"
                        name="file"
                        className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                        onChange={async (event) => {
                          (event.target.files && event.target.files[0]) &&
                            setFieldValue("file", event.target.files[0]);
                        }}
                      >
                      </input>
                      <ErrorMessage name="file" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                      type="button"
                      onClick={() => setSelectedMenuItem(3)}
                      className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                  >
                      Anterior
                  </button>
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>

      <div>
        <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">MIS DOCUMENTOS</h3>
        {
          isLoadingDocumentsApplicant ? <LoadingSpinner /> :
            isErrorDocumentsApplicant ? <Alerts title={"Error al cargar"} content={"Ha ocurrido un error inesperado, intenta cargar de nuevo el sitio web"} /> :
              getDocumentsApplicant.length < 1 ? <Alerts title={"Sin documentos"} content={"Agrega tu hoja de vida, asi aumentaras las opciones de ser reclutado"} /> :
                <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                  {getDocumentsApplicant?.map((document) => (
                    <li key={document.idFilesApplicant} className="col-span-1 flex rounded-md shadow-xl">
                      <div
                        className={'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md bg-dkt-brand-blue'}
                      >
                        <DocumentIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-grey-dkt-200 bg-white">
                        <div className="flex-1 truncate px-4 py-2 text-sm">
                          <span className="font-medium text-grey-dkt-900 hover:text-grey-dkt-600">
                            {/* {getDocumentsApplicant?.find(element => element._id == document._id)?.name} */}
                            {document.name}
                          </span>

                        </div>
                        <div className="flex-shrink-0 pr-2">
                          <button
                            onClick={() => deleteDocumentToApplicant(document.idFilesApplicant)}
                            type="button"
                            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-grey-dkt-400 hover:text-grey-dkt-500 focus:outline-none focus:ring-2 focus:ring-purple-dkt-500 focus:ring-offset-2"
                          >
                            <span className="sr-only">Open options</span>
                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
        }
      </div>
    </>
  )
}
