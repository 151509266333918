import { useNavigate } from 'react-router-dom'
import { ArrowCircleLeftIcon } from "@heroicons/react/solid"

export const Privacy = () => {

    let navigate = useNavigate();

    return (
        <article className="prose mx-auto my-8 px-4">
            <nav className="flex" aria-label="Breadcrumb">
                <ol role="list" className="lg:px-0 mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <button onClick={() => navigate(-1)} className="text-grey-dkt-400 hover:text-grey-dkt-500">
                                <ArrowCircleLeftIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                <span className="sr-only">Texto de regreso</span>
                            </button>
                        </div>
                    </li>
                </ol>
            </nav>

            <h2 className='md:inline'>POLÍTICA DE PRIVACIDAD CANDIDATOS</h2>
            <p className="italic">Actualizada el 16 de marzo de 2023. Publicada el 10 de mayo de 2023.</p>
            <h3>INFORMACIÓN DE LA EMPRESA</h3>
            <p>
                <strong>DECATHLON COLOMBIA, S.A.S</strong>, entidad comercial, legalmente constituida bajo las leyes de la República de Colombia, identificada con NIT. 900868271-1 y con domicilio en la calle 147 # 58C – 95 de la ciudad de Bogotá D.C., todo lo cual consta en el certificado de existencia y representación de la Cámara de Comercio de Bogotá, en su calidad de Responsable, pone a su disposición el siguiente Documento de Privacidad, a fin de que tenga pleno conocimiento sobre el tratamiento que se le dará a sus datos personales, así como facilitarle el ejercicio de los derechos que la Ley Estatutaria 1581 de 2012 le otorga.
            </p>
            <p>
                En caso de dudas o comentarios con relación al Documento de Privacidad, o a su derecho a la privacidad y protección de datos, por favor póngase en contacto a través del correo electrónico privacidad.colombia@decathlon.com
            </p>
            <h3>PREÁMBULO</h3>
            <p>
                El presente Documento de Privacidad explica la forma en la cual Decathlon Colombia S.A.S. (“Decathlon” o “nosotros”) recaba, utiliza y, en general, trata los datos personales de los candidatos que se postulan a las ofertas laborales  (individualmente “usted”, el “Candidato”) del sitio web www.trabajaconnosotros.decathlon.co  de acuerdo a lo establecido en la Ley Estatutaria 1581 de 2012 sobre protección de datos personales (la “Ley”).
            </p>
            <p>
                Por favor antes de proporcionar cualquier dato personal u otra información, lea con detenimiento este Documento de Privacidad y las Condiciones Generales de uso del portal, ya que estos documentos rigen su uso del Sitio y las compras que realiza a través del mismo.
            </p>
            <h3>DATOS PERSONALES QUE RECABAMOS</h3>
            <p>
                Sólo recabaremos datos personales si el Titular los proporciona voluntariamente al aplicar y completar todo el proceso de postulación a la oferta laboral de su interés, así como el envío de su hoja de vida.
            </p>
            <p>
                Los datos personales que Decathlon puede recabar y tratar de usted, previa autorización expresa,  pertenecen a las siguientes categorías:
            </p>
            <ul>
                <li>
                    Datos de identificación y contacto. Nombres, apellidos, dirección, correo electrónico personal, teléfono particular, celular, dirección de Protocolo de Internet (de ser el caso),
                </li>
                <li>
                    Imagen, en caso de que esta sea incluida en la hoja de vida,
                </li>
                <li>
                    Datos relacionados con su formación académica,
                </li>
                <li>
                    Datos relativos a la experiencia profesional,
                </li>
                <li>
                    Datos sobre sus preferencias, como prácticas deportivas,
                </li>
                <li>
                    información relacionada con referencias profesionales y;
                </li>
            </ul>
            <p>
                cualquier otra información que usted decida compartir (por ejemplo: preferencias profesionales, movilidad o disponibilidad geográfica, aspiración salarial, reconocimientos, entre otros)
            </p>
            <p>
                Por regla general, Decathlon no recauda datos personales sensibles, salvo exista ley u orden de autoridad competente que lo exija, caso en el cual se informará la naturaleza de los datos sensibles a recaudar y se solicitará la autorización para su tratamiento.
            </p>
            <p>
                Con respecto a los datos personales de terceros, al proporcionarlos usted como referencias personales o familiares que pueda incluir en su hoja de vida, usted declara y garantiza que ha informado a dichos terceros que nos compartirá ciertos de sus datos personales para utilización conforme al presente Documento de Privacidad, y que ellos conocen el Documento de Privacidad y están de acuerdo con su contenido y con que nos proporcione sus datos personales para su tratamiento.
            </p>
            <h3>CÓMO UTILIZAMOS SUS DATOS PERSONALES</h3>
            <p> Los datos personales que proporcione de manera voluntaria, se utilizarán para llevar a cabo las siguientes finalidades necesarias:
            </p>
            <ol>
                <li>
                    Crear una base de datos de los candidatos y para control interno.
                </li>
                <li>
                    Para procesar su postulación.
                </li>
                <li>
                    Para gestionar las ofertas de empleo publicadas en el portal.
                </li>
                <li>
                    Para realizar la verificación de referencias.
                </li>
                <li>
                    Para resolver sus Peticiones, Quejas y Reclamos que realicen los candidatos.
                </li>
                <li>
                    Para cumplir con nuestras obligaciones legales y con requerimientos de autoridad competente.
                </li>
                <li>
                    Para cumplir con las obligaciones que se generen con motivo de la relación jurídica entre usted y Decathlon.
                </li>
                <li>
                    Enviarle mensajes o comunicaciones para informarle sobre el proceso de selección al que ha aplicado.
                </li>
                <li>
                    Transferir internacionalmente sus datos personales a las filiales del Grupo Decathlon.
                </li>
                <li>
                    Transmisión internacional a los terceros que nos prestan sus servicios de almacenamiento y bases de datos en la nube.
                </li>
            </ol>
            <p>
                Si resulta seleccionado para ocupar el cargo, Decathlon Colombia S.A.S. incorporará la información de su aplicación en los registros del equipo de Recursos Humanos y serán usados para llevar a cabo el proceso de contratación. Dicha información podrá hacer parte de su carpeta laboral y usada para finalidades propias de la relación laboral.
            </p>
            <h3>A QUIÉN COMUNICAMOS SUS DATOS PERSONALES</h3>
            <p>
                Decathlon no vende, cede o renta los datos personales proporcionados por usted. Podremos compartir información estadística demográfica que no identifica a alguna persona a nuestros socios de negocios y/o subsidiarias del Grupo Decathlon. No obstante lo anterior, en otras situaciones podremos compartir ciertos datos personales con terceros, con el propósito de llevar a cabo las finalidades antes mencionadas, según se muestra a continuación.
            </p>
            <h4>Autoridades competentes:</h4>
            <p>Para cumplir con los requerimientos legales o solicitudes de autoridades, cuando la transferencia sea legalmente exigida para salvaguardar un interés público o para la procuración o administración de justicia; o bien, cuando sea necesaria para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.</p>

            <h4>A otras empresas del grupo Decathlon a nivel internacional:</h4>
            <p>Para cumplir las finalidades necesarias antes mencionadas.</p>

            < h3 > DÓNDE SE ENCUENTRAN NUESTROS SERVIDORES</h3 >
            <p>
                Decathlon almacena sus bases de datos de Decathlon Colombia en Estados Unidos de América. Este país ofrece un nivel adecuado de protección de datos personales de conformidad con la Circular Única de la Superintendencia de Industria y Comercio. No obstante, lo anterior Decathlon podrá almacenar sus datos personales en servidores locales, manteniendo los más altos estándares de seguridad posibles.
            </p>
            <h3>PRIVACIDAD DE MENORES DE EDAD</h3>
            <p>
                Decathlon está comprometida con la privacidad de menores de edad; por esto, no trata intencionalmente datos personales de menores de 18(dieciocho) años de edad.Por lo anterior durante el proceso de creación de su cuenta en el portal “Trabaja con Nosotros” se le solicitará su fecha de nacimiento para verificación durante el proceso de selección.
            </p>
            < h3 > CÓMO PROTEGEMOS SUS DATOS PERSONALES</h3 >
            <p>
                Decathlon ha adoptado prácticas y medidas de seguridad encaminadas a proteger sus datos personales de acceso, alteración, comunicación o destrucción no autorizados.El intercambio de datos a través del Sitio y el usuario se lleva a cabo por medio de un canal seguro de comunicación con protocolo “SSL” (Secure Socket Layer), y son encriptados y protegidos con firmas digitales.
            </p>
            < h3 > CUÁLES SON SUS DERECHOS Y CÓMO EJERCERLOS</h3 >
            <p>Como titular de datos personales, tiene derecho a:</p>
            <ol>
                <li>
                    Conocer, actualizar y rectificar sus datos personales sin limitación temporal alguna.
                </li>
                <li>
                    Solicitar prueba de la autorización otorgada a Decathlon del Tratamiento de sus datos, salvo cuando:
                    <ul>
                        <li>
                            Se trate de datos personales públicos. La información pública es aquella que puede ser obtenida sin reserva alguna, entre ella los documentos públicos, habida cuenta el mandato previsto en el artículo 74 de la Constitución Política. Esta información puede ser adquirida por cualquier persona, sin necesidad de autorización alguna para ello.
                        </li>
                        <li>
                            Su tratamiento se haya dado en el marco de urgencia médica o sanitaria (Ej.: Accidente en tienda)
                        </li>
                        <li>
                            Los datos personales han sido solicitados por las autoridades públicas en ejercicio de sus funciones legales o por orden judicial. En este caso, la autoridad debe demostrar que su solicitud está debidamente motivada y basada en una competencia funcional, de lo contrario se le solicitará la autorización respectiva.
                        </li>
                        <li>
                            El tratamiento de información está autorizado por la ley para fines históricos, estadísticos o científicos.
                        </li>
                    </ul>
                </li>
                <li>
                    Ser informados por Decathlon del Tratamiento de sus datos.
                </li>
                <li>
                    Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a la regulación de protección de datos personales.
                </li>
                <li>
                    Revocar su autorización y/o consentimiento otorgado a Decathlon para el tratamiento de sus datos.
                </li>
                <li>
                    Solicitar la supresión total o parcial de sus datos personales.
                </li>
            </ol>

            <h3>PROCEDIMIENTO PARA EL EJERCICIO DE DERECHOS</h3>
            <p>
                Para el ejercicio de cualquiera de sus derechos, deberá presentar o enviar una solicitud al Oficial de Protección de Datos de Decathlon, por medio del correo electrónico privacidad.colombia@decathlon.com, o físicamente en la dirección señalada al inicio del presente documento. La solicitud deberá contener y acompañarse, al menos, de la siguiente información y documentación:
            </p>
            <ol>
                <li>
                    Su nombre completo y correo electrónico para poder comunicarle la respuesta a su solicitud;
                </li>
                <li>
                    Los documentos que acrediten su identidad (copia de documento de identificación) o en su caso, los documentos que acrediten su representación legal (poder y copia de identificación oficial del representante);
                </li>
                <li>
                    Una descripción clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de los derechos;
                </li>
                <li>
                    Cualquier documento o información que facilite la localización de sus datos personales;
                </li>
                <li>
                    En caso de solicitar que sus datos personales sean corregidos o actualizados deberá de indicar también las modificaciones a realizarse y aportar la documentación que sustente su petición (donde los datos estén correctos);
                </li>
                <li>
                    En caso de que una persona menor de 18 (dieciocho) años nos haya proporcionado datos personales que usted como padre o tutor desee sean cancelados, deberá además incluir el nombre del menor e información que acredite que usted es el padre o tutor.
                </li>
            </ol>
            <p>
                Decathlon dará respuesta a su solicitud mediante un correo electrónico. Si su solicitud está incompleta se le podrá requerir que aporte la información faltante en un plazo de cinco días hábiles y, de no completar su solicitud, Decathlon la tendrá por no presentada. Si su solicitud está completa, se le contestará si la misma es procedente o no y las razones de la decisión, en un plazo máximo de diez (10) días hábiles contados a partir del día siguiente en que se haya recibido su solicitud completa.
            </p>
            <p>
                En caso de que no sea posible contestar en el término indicado anteriormente, Decathlon informará al interesado, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.
            </p>
            <p>
                Para más información con respecto al ejercicio de sus derechos, por favor envíenos un correo a privacidad.colombia@decathlon.com
            </p>
            <h3>CAMBIOS A ESTE DOCUMENTO DE PRIVACIDAD</h3>
            <p>
                Decathlon podrá efectuar en cualquier momento modificaciones o actualizaciones a este Documento de Privacidad según juzgue conveniente. Cuando hagamos modificaciones cambiaremos la fecha de la última actualización que aparece al inicio de este documento. Le alentamos a que revise con frecuencia esta página para que se mantenga actualizado con relación a cualquier cambio que pudiese existir. Usted está consciente y de acuerdo con que es su responsabilidad el revisar este Documento de Privacidad periódicamente para informarse de cualquier modificación. El uso del Sitio después de la publicación de la actualización de este Documento de Privacidad significará que ha leído la nueva versión y que consiente al tratamiento de sus datos personales de acuerdo a lo establecido en ésta.
            </p>
            <h3>SU ACEPTACIÓN DE ESTE DOCUMENTO DE PRIVACIDAD</h3>
            <p>
                El uso de la página web de trabaja con nosotros de Decathlon, en cualquier forma, significará que usted conoce, ha leído y está de acuerdo con la última versión de este Documento de Privacidad y todo lo establecido en el mismo, incluyendo el uso de sus datos personales para finalidades voluntarias y las transferencias de datos personales que pueden llevarse a cabo. Si no está de acuerdo con parte o la totalidad de este Documento de Privacidad, por favor no utilice el Sitio y evite proporcionar cualquier dato personal.
            </p>
            <p>
                En el evento en que se invitado a una entrevista, en tiendas u oficinas, a su ingreso, usted podrá estar siendo captado por nuestro circuito cerrado de televisión, en consecuencia mediante la conducta inequívoca de ingresar a la tienda, usted conoce y está de acuerdo con que su datos personales (imágenes) sean recabados con la finalidad de salvaguardar la seguridad y privacidad de nuestros clientes, usuarios y colaboradores.
            </p>
            <h3> CONTÁCTENOS</h3>
            <p>
                En caso de dudas o comentarios con relación al Documento de Privacidad, su derecho a la privacidad, o de requerir el manual de políticas y procedimientos de tratamiento de los datos personales y la protección de datos, por favor póngase en contacto a través del correo electrónico privacidad.colombia@decathlon.com.
            </p>
            <p>
                Le informamos también que, si tiene alguna queja con relación al tratamiento de sus datos personales o si somos omisos en contestar a sus solicitudes de derechos, puede acudir a la Superintendencia de Industria y Comercio a presentar su Queja a  través de la página web: https://www.sic.gov.co/
            </p>

            <div>

            </div>

        </article >
    )
}
