import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LocationMarkerIcon } from '@heroicons/react/solid'
import { Formik, Form, Field } from 'formik';
import toast from 'react-hot-toast';
import useSession from '../../hooks/useSession'
import { useMutation } from '@tanstack/react-query';
import { postApplicantionsCreate } from '../../selectors/applications';
// import { getFormStatus } from '../../selectors/applicants';

interface subscriptions {
    IdPlacePositions: string,
    perimeter: string,
    Latitude: string,
    Longitude: string,
    Name: string
}

interface Props {
    open: any,
    setOpen: any,
    setApplicationActive: any,
    data: subscriptions[]
}

export const ModalApplicationSelectPlace = ({ open, setOpen, setApplicationActive, data }: Props) => {
    const { currentUser } = useSession()
    const cancelButtonRef = useRef(null)

    // const { data:applicantStatus, isLoading, isError } = useQuery(["applicationFormStatus"], () => getFormStatus(currentUser?.id))

    // useEffect(() => {
    //     if(applicantStatus !== undefined){
    //         console.log(applicantStatus)
    //         if(applicantStatus.formations < 1 || applicantStatus.languages < 1 || applicantStatus.sports < 1){
    //             toast.success("Completa tu perfil para que tengas mas opciones de ser reclutado", { duration: 7000, style: { background: "#007DBC", color: "white" } })
    //         }
    //     }
    // }, [])
    
    const { mutate: createApplicantionToApplicant } = useMutation(postApplicantionsCreate, {
        onSuccess: ({status}) => {
            toast.success(status, { style: { background: "#3643BA", color: "white" } })
            setOpen(false)
            setApplicationActive(true)
        },
        onError: ({ error }) => {
            console.log(error)
            toast.error("No fue posible aplicar a la vacante, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        }
    })

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-grey-dkt-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

                                <Formik
                                    initialValues={{
                                        idApplicant: currentUser?.id,
                                        idPlacePositions: []
                                    }}
                                    onSubmit={
                                        (values) => {
                                            if (values.idPlacePositions.length === 0) {
                                                toast.error("Selecciona por lo menos una ubicación.", { style: { background: "#E3262F", color: "white" } })
                                            } else {
                                                let datos = values.idPlacePositions.map((x) => {
                                                    return ({ "idApplicant": values.idApplicant, "idPlacePositions": x })
                                                })
                                                console.log(datos)
                                                createApplicantionToApplicant(datos);
                                                // setOpen(false)
                                            }
                                        }
                                    }
                                >
                                    {
                                        () => (
                                            <Form>
                                                <div className="sm:flex sm:items-start">
                                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                        <LocationMarkerIcon className="h-6 w-6 text-dkt-brand-blue" aria-hidden="true" />
                                                    </div>
                                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-grey-dkt-900">
                                                            <div>
                                                                <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">UBICACIÓN</h3>
                                                                <p className="mt-1 text-lg">Selecciona la ubicación en la que deseas aplicar</p>
                                                            </div>
                                                        </Dialog.Title>
                                                        <div className="mt-2">
                                                            <div className="space-y-6 sm:space-y-5 divide-y divide-grey-dkt-200">
                                                                <div className="pt-6 sm:pt-5">
                                                                    <div role="group" aria-labelledby="label-email">
                                                                        <div className="sm:grid sm:items-baseline  grid grid-cols-1 gap-3">
                                                                            {
                                                                                data.map((placePositions: subscriptions) => (
                                                                                    <div key={placePositions.IdPlacePositions} className="mt-4 sm:mt-0 sm:col-span-2">
                                                                                        <div className="relative flex items-start">
                                                                                            <div className="flex items-center h-5">
                                                                                                <Field
                                                                                                    id={placePositions.Name}
                                                                                                    name="idPlacePositions"
                                                                                                    type="checkbox"
                                                                                                    className="text-lgfocus:ring-orange-dkt-400 h-4 w-4 text-orange-dkt-400 border-grey-dkt-300 rounded"
                                                                                                    value={placePositions.IdPlacePositions}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="ml-3 text-sm">
                                                                                                <label htmlFor={placePositions.Name} className="font-medium">
                                                                                                    {/* {`${placePositions.perimeter}` + ` ${placePositions.Name ? "- " + placePositions.Name : ""}`} */}
                                                                                                    {`${placePositions.perimeter} ${placePositions.Name ? "- " + placePositions.Name : ""}`}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                    <button
                                                        type="submit"
                                                        className="inline-flex w-full justify-center rounded-full bg-dkt-brand-blue px-4 py-2 text-base font-medium text-dkt-green-01 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm hover:text-white"
                                                    >
                                                        APLICAR
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpen(false)}
                                                        className="mt-3 inline-flex w-full justify-center rounded-full border border-grey-dkt-300 bg-white px-4 py-2 text-base font-medium text-grey-dkt-700 shadow-sm hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-purple-dkt-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                                    >
                                                        Cancelar
                                                    </button>
                                                </div>
                                            </Form>
                                        )
                                    }
                                </Formik>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
