import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import { AcademicCapIcon, TrashIcon } from '@heroicons/react/solid'
import toast from 'react-hot-toast';
import useSession from '../../../../hooks/useSession';
import { createFormationsApplicant, formationsApplicant, removeFormationsApplicant } from '../../../../selectors/applicants';
import { LoadingSpinner } from '../../../../components/ui/LoadingSpinner';
import { Alerts } from '../../../../components/ui/Alerts';

interface Props {
    setSelectedMenuItem?: any;
}

export const Studies = ({ setSelectedMenuItem }: Props) => {

    const queryClient = useQueryClient();

    const { currentUser } = useSession()

    const { data: getFormationApplicant, isLoading: isLoadingFormationApplicant, isError: isErrorFormationApplicant } = useQuery(["formationApplicant"], () => formationsApplicant(currentUser?.id))

    const { mutate: addFormationToApplicant } = useMutation(createFormationsApplicant, {
        onSuccess: (newFormation) => {
            getFormationApplicant?.push(newFormation)
            toast.dismiss()
            toast.success("Has agregado un nuevo estudio.", { style: { background: "#3643BA", color: "white" } })
        },
        onError: ({ error }) => {
            toast.dismiss()
            toast.error("No fue posible agregar tu nuevo estudio, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
            console.log(error)
        },
        onMutate: () => {
          toast.loading("Agregando un estudio.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    const { mutate: deleteFormationToApplicant } = useMutation(removeFormationsApplicant, {
        onSuccess: () => {
            queryClient.invalidateQueries(["formationApplicant"])
            toast.dismiss()
            toast.success("Has eliminado un estudio.", { style: { background: "#3643BA", color: "white" } })
        },
        onError: ({ error }) => {
            toast.dismiss()
            toast.error("No fue posible eliminar tu estudio, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
            console.log(error)
        },
        onMutate: () => {
          toast.loading("Eliminando un estudio.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    const handleNextForm = () => {
        if(getFormationApplicant!.length > 0){
            setSelectedMenuItem(2)
        }else{
            toast.dismiss()
            toast.error("Por favor ingresa tus estudios realizados.", { style: { background: "#E3262F", color: "white" } })
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    idApplicant: currentUser?.id,
                    formation: 'Primaria',
                    institute: '',
                    title: '',
                }}
                onSubmit={
                    (values, { resetForm } ) => {
                        addFormationToApplicant(values)
                        resetForm()
                    }
                }
                validationSchema={
                    Yup.object({
                        formation: Yup.string()
                            .required('Este campo es obligatorio'),
                        institute: Yup.string()
                            .required('Este campo es obligatorio'),
                        title: Yup.string()
                            .required('Este campo es obligatorio')
                    })
                }
            >
                {
                    () => (
                        <Form className="space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto max-w-7xl mt-16">
                            <div className="divide-y divide-grey-dkt-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                                <div>
                                    <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">ESTUDIOS</h3>
                                    <p className="mt-1 text-lg">Que formaciones técnicas, tecnologías, profesionales, diplomados, doctorados, certificaciones u otros tienes</p>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="formation" className="block text-lg font-medium">
                                            Nivel educativo
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                as="select"
                                                id="formation"
                                                name="formation"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            >
                                                <option>Primaria</option>
                                                <option>Bachillerato</option>
                                                <option>Técnico</option>
                                                <option>Tecnológico</option>
                                                <option>Pregrado</option>
                                                <option>Posgrado</option>
                                                <option>Especialización</option>
                                                <option>Maestría</option>
                                                <option>Doctorado</option>
                                                <option>Diplomado</option>
                                                <option>Certificación</option>
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="institute" className="block text-lg font-medium">
                                            Institución
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="institute"
                                                id="institute"
                                                autoComplete="institute"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="institute" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                        <label htmlFor="title" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                            Nombre del programa
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <Field
                                                type="text"
                                                id="title"
                                                name="title"
                                                autoComplete="title"
                                                className=" focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="title" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        onClick={() => setSelectedMenuItem(0)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                    >
                                        Anterior
                                    </button>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                    >
                                        Guardar
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleNextForm()}
                                        className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                    >
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
            
            <div>
                <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">MIS ESTUDIOS</h3>
                {
                    isLoadingFormationApplicant ? < LoadingSpinner /> :
                        isErrorFormationApplicant ? <Alerts title={"Error al cargar"} content={"Ha ocurrido un error inesperado, intenta cargar de nuevo el sitio web"} /> :
                        getFormationApplicant.length < 1 ? <Alerts title={"Sin estudios"} content={"Agrega los estudios que has realizado, asi aumentaras las opciones de ser reclutado"} /> :
                            <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
                                {getFormationApplicant.map((formation) => (
                                    <li key={formation.idFormationsApplicant} className="col-span-1 flex rounded-md shadow-xl">
                                        <div
                                            className={'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md bg-dkt-brand-blue'}
                                        >
                                            <AcademicCapIcon className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <div className="flex flex-1 items-center justify-between rounded-r-md border-t border-r border-b border-grey-dkt-200 bg-white">
                                            <div className="flex-1 px-4 py-2 text-sm">
                                                <span className="mt-1 text-base font-semibold tracking-tight text-dkt-brand-blue">
                                                    {formation.title}
                                                </span>
                                                <span className="flex items-center">- {formation.institute}</span>
                                                <span className="flex items-center">- {formation.formation}</span>

                                            </div>
                                            <div className="flex-shrink-0 pr-2">
                                                <button
                                                    onClick={() => deleteFormationToApplicant(formation.idFormationsApplicant)}
                                                    type="button"
                                                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-grey-dkt-400 hover:text-grey-dkt-500 focus:outline-none focus:ring-2 focus:ring-purple-dkt-500 focus:ring-offset-2"
                                                >
                                                    <span className="sr-only">Open options</span>
                                                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                }
            </div>
        </>
    )
}
