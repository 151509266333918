import { NavLink, Navigate, useParams, useNavigate } from "react-router-dom"
import { Footer } from "../../ui/Footer"
import { useQuery } from "@tanstack/react-query"
import { getPositionId } from "../../../../selectors/positions"
import { isSignedIn } from "../../../../utils/isSignedIn"
import { useEffect, useState } from "react"
import { getApplicantionsHistoric } from "../../../../selectors/applications"
import useSession from "../../../../hooks/useSession"
import { ModalApplicationSelectPlace } from "../../../../components/ui/ModalApplicationSelectPlace"
import { LoadingSpinner } from "../../../../components/ui/LoadingSpinner"
import { Alerts } from "../../../../components/ui/Alerts"
import { LocationMarkerIcon } from "@heroicons/react/outline"


export const VacanciesDetail = () => {

    const { catId, vacanciesId } = useParams()
    const { data, isLoading, isError } = useQuery(["Positions", vacanciesId], () => getPositionId(vacanciesId))
    const { currentUser } = useSession()
    const { data: applicationHistory } = useQuery(["myApplications"], () => getApplicantionsHistoric(currentUser?.id), { enabled: currentUser ? true : false })
    const [applicationActive, setApplicationActive] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    let navigate = useNavigate();

    useEffect(() => {
        if (applicationHistory) {
            const applicationActive =
                !applicationHistory
                    .find(application =>
                        application.idPosition == data?.description.idPosition &&
                        application.applicationstatus == true)
            setApplicationActive(!applicationActive)
        }
    }, [data, applicationHistory]);

    return (
        <>
            <main className="bg-dkt-bg-alternative">
                {
                    isLoading ? < LoadingSpinner /> :
                        isError ? <Alerts title={"A ocurrido un error inesperado"} content={"Inténtalo de nuevo o comunícate con el proveedor del servicio"}></Alerts> :
                            <>
                                <ModalApplicationSelectPlace open={openModal} setOpen={setOpenModal} setApplicationActive={setApplicationActive} data={data?.subscriptions} />
                                <h1 className="lg:max-w-7xl max-w-xl pl-6 pt-12 mx-auto lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-dkt-brand-blue mb-10 font-bold">
                                    {data?.description.title}
                                </h1>
                                <div className="pb-4 lg:grid lg:grid-cols-12 lg:gap-8 max-w-7xl px-4 sm:px-6 mx-auto">
                                    <div className="md:max-w-2xl lg:col-span-6 2xl:text-left xl:text-left lg:text-left md:text-center sm:text-center text-center content-center mx-auto">
                                        <div className="py-8">
                                            <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-dkt-brand-blue pb-3 font-bold">
                                                Tu perfil
                                            </h1>
                                            <h2 className="text-black" dangerouslySetInnerHTML={{ __html: data ? data.description.mandatories : '' }} />
                                        </div>
                                        <div className="py-8">
                                            <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-dkt-brand-blue pb-3 font-bold">
                                                Tu misión
                                            </h1>
                                            <h2 className="text-black" dangerouslySetInnerHTML={{ __html: data ? data.description.description : '' }} />
                                        </div>
                                        <div className="py-8">
                                            <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-dkt-brand-blue pb-3 font-bold">
                                                Tu recompensa
                                            </h1>
                                            <h2 className="text-black" dangerouslySetInnerHTML={{ __html: data ? data.description.experience : '' }} />
                                        </div>
                                    </div>
                                    <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex">
                                        <div className="flex-1 w-full content-center">
                                            <img
                                                src={data.description.image
                                                    ? `${process.env.REACT_APP_API_URL!.replaceAll('/v1', '')}/${data.description.image}`
                                                    : 'https://www.ondho.com/wp-content/uploads/2017/10/171023-decathlon-1200x1200.png'}
                                                alt="Product screenshot"
                                                className="mx-auto object-cover"
                                            />
                                            <div className="sm:flex justify-center mx-auto pt-12">
                                                <div className="text-sm w-full">
                                                    {
                                                        !isSignedIn() ?
                                                            <button
                                                                onClick={() => { navigate("/login") }}
                                                                type="button"
                                                                style={{ width: "30.8rem" }}
                                                                className="justify-center relative flex items-center text-dkt-brand-blue rounded-full px-4 py-1.5 ring-1 hover:to-blue-dkt-700 hover:bg-dkt-brand-blue hover:text-white ring-dkt-brand-blue m-auto"
                                                            >
                                                                INICIA SESIÓN O REGÍSTRATE PARA APLICAR
                                                            </button>
                                                            :
                                                            applicationActive ?
                                                                <button
                                                                    onClick={() => { navigate("/myApplications") }}
                                                                    type="button"
                                                                    style={{ width: "30.8rem" }}
                                                                    className="justify-center relative flex items-center text-dkt-brand-blue rounded-full px-4 py-1.5 ring-1 hover:to-blue-dkt-700 hover:bg-dkt-brand-blue hover:text-white ring-dkt-brand-blue m-auto"
                                                                >
                                                                    YA APLICASTE A ESTA VACANTE
                                                                </button>
                                                                :
                                                                <button
                                                                    onClick={() => setOpenModal(true)}
                                                                    type="button"
                                                                    style={{ width: "30.8rem" }}
                                                                    className="justify-center relative flex items-center text-dkt-brand-blue rounded-full px-4 py-1.5 ring-1 hover:to-blue-dkt-700 hover:bg-dkt-brand-blue hover:text-white ring-dkt-brand-blue m-auto"
                                                                >
                                                                    APLICAR A ESTA VACANTE
                                                                </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                }
                <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-grey-dkt-300" />
                    </div>
                </div>

                <div className="mx-auto max-w-7xl px-4 sm:px-6 py-4">
                    <div className="px-2 ">
                        <LocationMarkerIcon className="absolute h-10 w-10 text-dkt-brand-blue" aria-hidden="true" />
                        <h2 className="ml-10 mb-30 mt-1 block text-3xl tracking-tight text-dkt-brand-blue">Ubicación y deporte</h2>
                        <dt className='px-2 py-5'>
                            {
                                data?.subscriptions.map((place: any, index) => (
                                    <div key={index}>
                                        <LocationMarkerIcon className="absolute h-6 w-6" aria-hidden="true" />
                                        {/* <p className="ml-9 text-lg text-grey-dkt-500 sm:text-xl lg:text-lg">{`${place.perimeter}` + ` ${place.Name ? "- " + place.Name : ""}`}</p> */}
                                        <p className="ml-9 text-lg sm:text-xl lg:text-lg">{`${place.perimeter} ${place.Name ? "- " + place.Name : ""}`}</p>
                                    </div>
                                ))
                            }
                        </dt>
                    </div>
                </div>
            </main >
            <Footer />
        </>
    )
}