import { isExpired } from "react-jwt"
import { TOKEN_STORAGE_KEY } from "../hooks/useSession"
import { clearStorage, isEmpty } from "./functions"


export const isSignedIn = () => {

    const accessToken = localStorage.getItem(TOKEN_STORAGE_KEY)
    let correctInfo = false

    if (accessToken) {
        correctInfo = !isEmpty(accessToken) && !isExpired(accessToken)
    }

    if (!correctInfo) clearStorage()

    return correctInfo
}