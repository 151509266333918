import { Vacancies, VacanciesByCategories, VacanciesDetail } from "../components/pages/vacancies";
import { AboutUs } from "../components/pages/home/aboutUs";
import { HomePage } from "../components/pages/home/homePage/HomePage";
import { WhyWe } from "../components/pages/home/whyWe";
import { Login, Register } from "../components/auth/";
import { AccountSettings, MyProfile } from "../components/pages/myProfile";
import { MyNominations } from "../components/pages/myNominations";
import { Privacy } from "../components/pages/privacy/Privacy";


type JSXComponent = () => JSX.Element;

interface Route {
    path: string,
    Component: JSXComponent,
    name?: string,
    style?: boolean,
    userNavigation?: boolean,
    navBarNavigation?: boolean,
}

export const routes: Route[] = [
    { path: '/', Component: HomePage},
    { path: 'aboutUs', Component: AboutUs, name: '¿Quiénes somos?', navBarNavigation: true },
    { path: 'whyWe', Component: WhyWe, name: '¿Por qué nosotros?', navBarNavigation: true },
    { path: 'vacancies', Component: Vacancies, name: 'Vacantes', style: true, navBarNavigation: true },
    { path: 'vacancies/:catId', Component: VacanciesByCategories },
    { path: 'vacancies/:catId/:vacanciesId', Component: VacanciesDetail },
    { path: 'myProfile', Component: MyProfile, name: 'MI PERFIL', userNavigation: true},
    { path: 'myApplications', Component: MyNominations, name: 'MIS CANDIDATURAS', userNavigation: true},
    { path: 'accountSettings', Component: AccountSettings, name: 'CONFIGURACIÓN', userNavigation: true},
    { path: 'register', Component: Register, name: 'Regístrate'},
    { path: 'login', Component: Login},
    { path: 'privacy', Component: Privacy, name: 'PRIVACIDAD'},
]


