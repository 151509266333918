import React, { useState } from 'react'
import { AcademicCapIcon, ChatAlt2Icon, UserCircleIcon, LightningBoltIcon } from '@heroicons/react/outline'
import { FavoriteSports, Languages, Studies, PersonalInformation, Documents } from '.'
import { isSignedIn } from '../../../../utils/isSignedIn'
import { NoAccess } from '../../../../components/pages/landingPage/NoAccess'
import { BannerTitle } from '../../ui/BannerTitle'

export interface NavigationItem {
    name: string,
    component: React.ReactNode
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export const MyProfile = () => {

    const [selectedMenuItem, setSelectedMenuItem] = useState(0)

    const navigation = [
        { name: 'Perfil', component: <PersonalInformation />, icon: UserCircleIcon, current: true },
        { name: 'Formaciones', component: <Studies />, icon: AcademicCapIcon, current: false },
        { name: 'Idiomas', component: <Languages />, icon: ChatAlt2Icon, current: false },
        { name: 'Deportes', component: <FavoriteSports />, icon: LightningBoltIcon, current: false },
        { name: 'Documentos', component: <Documents />, icon: LightningBoltIcon, current: false },
    ]

    return (
        !isSignedIn() ?
            <NoAccess />
            :
            <div className="bg-white pb-6">
                <BannerTitle image="/assets/images/v2/Perfil_Aspirante.jpg" title="Mi perfil" />
                <div className="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8">
                    <div className="space-y-12">
                        <div className="border-b border-grey-dkt-200 pb-5 sm:pb-0">
                            <div className="mt-3 sm:mt-4">
                                <div className="sm:hidden">
                                    <label htmlFor="current-tab" className="sr-only">
                                        Select a tab
                                    </label>
                                    <select
                                        id="current-tab"
                                        name="current-tab"
                                        className="block w-full rounded-md py-2 pl-3 pr-10 text-base focus:outline-none sm:text-sm"
                                        defaultValue={navigation.find((tab) => tab.current)?.name}
                                        onChange={event => setSelectedMenuItem(Number(event.target.value))}
                                        value={selectedMenuItem}
                                    >
                                        {navigation.map((tab, index) => (
                                            <option key={tab.name} value={index}>{tab.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="hidden sm:block">
                                    <nav className="-mb-px flex space-x-8">
                                        {navigation.map((tab, index) => (
                                            <button
                                                key={tab.name}
                                                onClick={() => setSelectedMenuItem(index)}
                                                className={classNames(
                                                    navigation[selectedMenuItem].name === tab.name
                                                        ? 'border-dkt-green-01 text-dkt-green-01 text-lg'
                                                        : ' border-grey-dkt-300 hover:border-grey-dkt-500',
                                                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                                                )}
                                                aria-current={navigation[selectedMenuItem].name ? 'page' : undefined}
                                            >
                                                {tab.name}
                                            </button>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
                            {navigation[selectedMenuItem].component}
                        </div>
                    </div>
                </div>
            </div>
    )
}
