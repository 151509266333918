import { Link } from 'react-router-dom'

export const RegisterFooter = () => {
    return (
        <footer className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
                <div className="mt-8 md:order-1 md:mt-0">
                    <p className="text-center text-sm leading-5 text-gray-500">
                        Al crear una cuenta, aceptas los Términos del servicio.
                        Para obtener más información sobre las prácticas de privacidad del portal de reclutamiento de Decathlon Colombia, consulte las
                        <Link
                            to={`/privacy`}
                            className="mt-1 text-dkt-brand-blue"
                        >
                            &nbsp;política de privacidad.&nbsp;
                        </Link>
                        Ocasionalmente le enviaremos correos electrónicos
                        relacionados con la cuenta.
                    </p>
                </div>
            </div>
        </footer>
    )
}
