import { ApplicantDocuments, ApplicantFormations, ApplicantFormStatus, ApplicantLanguage, ApplicantProfile, ApplicantSports } from "../interfaces/Applicant"
import { recluiterApi } from "../utils/axios"

interface Documents {
    idApplicant: string
    file: FormData
}

export const getById = (id: string | undefined): Promise<ApplicantProfile> => {
    return recluiterApi.get<Array<ApplicantProfile>>(`/applicants/getById/${id}`).then(res => res.data[0])
}

export const languagesApplicant = (id: string | undefined): Promise<Array<ApplicantLanguage>> => {
    return recluiterApi.get<Array<ApplicantLanguage>>(`/applicants/languagesApplicant/${id}`).then(res => res.data)
}

export const createLanguageApplicant = (language: ApplicantLanguage): Promise<ApplicantLanguage> => {
    return recluiterApi.post<ApplicantLanguage>(`/applicants/createLanguageApplicant`, language).then(res => res.data)
}

export const removeLanguageApplicant = (idLanguage: any) => {
    return recluiterApi.delete(`/applicants/deleteApplicantLanguage/${idLanguage}`).then(res => res.data)
}

export const sportsApplicant = (id: string | undefined): Promise<Array<ApplicantSports>> => {
    return recluiterApi.get<Array<ApplicantSports>>(`/applicants/sportsApplicant/${id}`).then(res => res.data)
}

export const createSportApplicant = (sport: ApplicantSports): Promise<ApplicantSports> => {
    return recluiterApi.post<ApplicantSports>(`/applicants/createSportApplicant`, sport).then(res => res.data)
}

export const removeSportApplicant = (idSport: any) => {
    return recluiterApi.delete(`/applicants/deleteSportApplicant/${idSport}`).then(res => res.data)
}

export const formationsApplicant = (id: string | undefined): Promise<Array<ApplicantFormations>> => {
    return recluiterApi.get<Array<ApplicantFormations>>(`/applicants/formations/${id}`).then(res => res.data)
}

export const createFormationsApplicant = (sport: ApplicantFormations): Promise<ApplicantFormations> => {
    return recluiterApi.post<ApplicantFormations>(`/applicants/formations/create`, sport).then(res => res.data)
}

export const removeFormationsApplicant = (idFormation: any) => {
    return recluiterApi.delete(`/applicants/formations/${idFormation}`).then(res => res.data)
}

export const getFormStatus = (id: string | undefined): Promise<ApplicantFormStatus> => {
    return recluiterApi.get<ApplicantFormStatus>(`/applicants/formStatus/${id}`).then(res => res.data)
}

export const filesApplicant = (id: string | undefined): Promise<Array<ApplicantDocuments>> => {
    return recluiterApi.get<Array<ApplicantDocuments>>(`/applicants/files/${id}`).then(res => res.data)
}

export const createDocumentApplicant = ({ idApplicant, file }: Documents): Promise<ApplicantDocuments> => {
    return recluiterApi.post<ApplicantDocuments>(`/applicants/createFileApplicant/${idApplicant}`, file).then(res => res.data)
}

export const removeDocumentApplicant = (idFile: any) => {
    return recluiterApi.delete(`/applicants/files/${idFile}`).then(res => res.data)
}




