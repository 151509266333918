import { AboutUsContent } from "../../../../../interfaces/Contents";
import { FlipCard } from "../../../ui/FlipCard"

interface Props {
    aboutUsContent?: AboutUsContent;
}

export const OurValues = ({ aboutUsContent }: Props) => {

    const values = [
        {
            name: "GENEROSIDAD",
            image: '/assets/images/v2/quienesSomos/Pagina-Quienes-Somos-9.jpg',
            description: aboutUsContent?.generosity ?? ''
        },
        {
            name: "VITALIDAD",
            image: '/assets/images/v2/quienesSomos/Pagina-Quienes-Somos-7.jpg',
            description: aboutUsContent?.vitality ?? ''
        },
        {
            name: "RESPONSABILIDAD",
            image: '/assets/images/v2/quienesSomos/Pagina-Quienes-Somos-8.jpg',
            description: aboutUsContent?.responsibility ?? ''
        },
        {
            name: "AUTENTICIDAD",
            image: '/assets/images/v2/quienesSomos/Pagina-Quienes-Somos-10.jpg',
            description: aboutUsContent?.authenticity ?? ''
        }
    ]

    return (
        <>
            <div className="xl:max-w-7xl lg:max-w-6xl md:max-w-4xl sm:max-w-2xl mx-auto py-12 px-4">
                <h1 className="text-5xl font-bold pb-6">
                    <span className="text-black">Nuestros </span>
                    <span className="text-dkt-brand-blue"> valores</span>
                </h1>
                <h3>
                    Descubre cómo nuestros valores inspiran cada acción y definen nuestra cultura. Son la fuerza que guía nuestro trabajo diario y lo que nos une como equipo.
                    Ven y sé parte de un lugar donde los valores no solo se viven, sino que transforman la manera en que 
                </h3>
            </div>
            <ul
                role="list"
                className="mx-auto grid lg:mx-0 lg:max-w-none bg-dkt-bg-alternative sm:grid-cols-2 lg:grid-cols-4"
            >
                {
                    values.map((value) => (
                        <li>
                            <FlipCard title={value.name} image={value.image} content={value.description} />
                        </li>
                    ))
                }

            </ul>
        </>
    )
}