import axios from "axios";
import { recluiterApi } from "../utils/axios";

export interface LoginApplicant {
    email: string,
    password: string
}

export interface LoginResponse {
    token: string
}

export const postLogin = (user: LoginApplicant | undefined): Promise<LoginResponse> => {
    return recluiterApi.post<LoginResponse>(`/applicants/login`, user).then(res => res.data)
}

export const postRecaptcha = (even: any) => {
    return axios.post(
        `https://recaptchaenterprise.googleapis.com/v1/projects/toolscol-preprod-hbot/assessments?key=AIzaSyAMenJZzeCqrkTmpH3NW-hEwzYfXSt6gtE`,
        even
    ).then(
        res => res.data
    )
}