import { useQuery } from "@tanstack/react-query"
import useSession from "../../../../hooks/useSession"
import { getApplicantionsHistoric } from "../../../../selectors/applications"
import { isSignedIn } from "../../../../utils/isSignedIn"
import { NoAccess } from "../../../../components/pages/landingPage/NoAccess"
import { LoadingSpinner } from "../../../../components/ui/LoadingSpinner"
import { Alerts } from "../../../../components/ui/Alerts"
import { ListMyNominations} from "."
import { BannerTitle } from "../../ui/BannerTitle"


export const MyNominations = () => {

    const { currentUser } = useSession()
    const { data, isLoading, isError } = useQuery(["myApplications"], () => getApplicantionsHistoric(currentUser?.id))

    return (
        !isSignedIn() ?
            <NoAccess />
            :
            <>
                <BannerTitle image="/assets/images/v2/vacantes/Candidaturas.jpg" title="Mis candidaturas" />
                <div className="min-h-full flex">
                    <div className="flex flex-col w-0 flex-1">
                        <main className="flex-1 mt-6">
                            {
                                isLoading ? <LoadingSpinner /> :
                                    isError ? <Alerts title={"Error al cargar"} content={"Ha ocurrido un error inesperado, intenta cargar de nuevo el sitio web"} /> :
                                        data.length === 0 ? <Alerts title={"Sin candidaturas"} content={"Aun no has aplicado a ninguna oferta publicada"} /> :
                                            data.map((applicant, idx) => (
                                                <ListMyNominations key={applicant.IdApplications} applicant={applicant} idx={idx} />
                                            ))
                            }
                        </main>
                    </div>
                </div>
            </>

    )
}
