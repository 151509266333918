import { useQuery } from "@tanstack/react-query"
import { BannerTitle } from "../../../ui/BannerTitle"
import { Footer } from "../../../ui/Footer"
import { OurHistory, OurValues } from "."
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, Autoplay } from 'swiper/modules';
import { getAboutUsContent } from "../../../../../selectors/contents"

export const AboutUs = () => {

    const { data: aboutUsContent } = useQuery([], () => getAboutUsContent())

    const peoples = [
        {
            imageUrl: "/assets/images/v2/quienesSomos/JPG07256.JPG"
        },
        {
            imageUrl: "/assets/images/v2/quienesSomos/JPG04253.png"
        },
        {
            imageUrl: "/assets/images/v2/quienesSomos/Untitled_1.109.1.png"
        },
        {
            imageUrl: "/assets/images/v2/quienesSomos/JPG02596.png"
        },
        {
            imageUrl: "/assets/images/v2/quienesSomos/JPG04219.png"
        },
        {
            imageUrl: "/assets/images/v2/quienesSomos/DSC00614_Edit.png"
        }
    ]

    return (
        <>
            {/** ¿Quiénes somos? */}
            <BannerTitle image="/assets/images/v2/quienesSomos/Pagina-Quienes-Somos-6.jpg" title="¿Quienes somos" />

            <main className="mt-16">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8 max-w-7xl px-4 sm:px-6 mx-auto pb-16">
                    <div className="md:max-w-2xl lg:col-span-5 2xl:text-left xl:text-left lg:text-left md:text-center sm:text-center text-center content-center mx-auto">
                        <div>
                            <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-black pb-6 font-bold">Nuestro sentido</h1>
                            <h2 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-dkt-brand-blue font-bold">Move people</h2>
                            <h2 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-dkt-brand-blue pb-2 font-bold">through the</h2>
                            <h2 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-dkt-green-01 font-bold bg-dkt-brand-blue">wonders of sport</h2>
                        </div>
                    </div>
                    <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-7 lg:flex">
                        <div className="flex-1 w-full">
                            <Swiper
                                modules={[Navigation, A11y, Autoplay]}
                                slidesPerView={1}
                                loop={true}
                                navigation
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false
                                }}
                            >

                                {peoples.map((people => (
                                    <SwiperSlide key={people.imageUrl}>
                                        <img
                                            src={people.imageUrl}
                                            alt="Product screenshot"
                                            className="mx-auto object-cover"
                                        />
                                    </SwiperSlide>
                                )))}
                            </Swiper>
                            {/* <img
                                src="/assets/images/newlookandfeeltcn/colina.jpg"
                                alt="Product screenshot"
                                className="mx-auto object-cover"
                            /> */}
                        </div>
                    </div>
                </div>
            </main >

            {/** Nuestra historia */}
            <OurHistory aboutUsContent={aboutUsContent} />

            {/** Nuestros valores */}
            <OurValues aboutUsContent={aboutUsContent} />

            <Footer />
        </>
    )
}