import axios from 'axios'
import { TOKEN_STORAGE_KEY } from "../hooks/useSession";
import { clearStorage } from './functions';

export const recluiterApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

recluiterApi.interceptors.request.use(async function (config) {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY)
  config.headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  return config;
}, function (error) {
  return Promise.reject(error);
})

recluiterApi.interceptors.response.use(async function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    // alert(error.response.status)
    clearStorage()
    // window.location.href = "/noAccess"
  }
  return Promise.reject(error);
})