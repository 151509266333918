import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, Autoplay } from 'swiper/modules';

export const Banner = () => {

    const peoples = [
        {
            name: 'Rodrigo Avila',
            role: 'Líder Ecommerce',
            imageUrl: "/assets/images/v2/homePage/rodri.png",
            testimonyUrl: "https://www.youtube.com/embed/9FCJHHwmUoI",
            testimonyTitle: "Cuando la pasión por el deporte y el trabajo se unen",
            bio:
                'Trabajar en Decathlon para mí ha sido increíble, un lugar donde tus habilidades son valoradas y potenciadas. Ha sido clave para mi desarrollo, permitiéndome crecer personal y profesionalmente.',
        },
        {
            name: 'Diego Perez',
            role: 'Líder de soluciones de software',
            imageUrl: "/assets/images/v2/homePage/diego.jpg",
            testimonyUrl: "https://www.youtube.com/embed/Il8p91V_oqA",
            testimonyTitle: "Aprendemos haciendo",
            bio:
                'Decathlon es un gran lugar para trabajar por que hago parte de una comunidad que promueve el deporte y tenemos una cultura centrada en el desarrollo de los empleados donde tenemos oportunidades de crecer constantemente',
        },
        {
            name: 'Ángela Zamorano',
            role: 'Directora Tienda',
            imageUrl: "/assets/images/v2/homePage/angela.png",
            testimonyUrl: "https://www.youtube.com/embed/c_bdE90emsw",
            testimonyTitle: "Crecemos con Decathlon",
            bio:
                'Decathlon se interesa por desarrollar mis competencias. Esto ha sido muy bueno para mí porque en la medida que la empresa ha ido creciendo en Colombia yo he ido creciendo con Decathlon',
        },
        {
            name: 'Vannesa Delgado',
            role: 'Organizador tienda',
            imageUrl: "/assets/images/v2/homePage/Vanessa.jpg",
            testimonyUrl: "https://www.youtube.com/embed/Hff7Q9hJ7xY",
            testimonyTitle: "Mi segundo hogar",
            bio:
                'Decathlon es como mi segundo hogar, es donde me  he  desarrollado profesionalmente sin dejar de lado mi vida personal',
        },
    ]

    return (
        <>
            <section className="bg-dkt-brand-blue">
                <div className="xl:max-w-7xl lg:max-w-6xl md:max-w-4xl sm:max-w-2xl mx-auto px-4">
                    <div className="relative isolate">
                        <div className="mx-auto py-14">
                            <div className="text-center">
                                <h1 className="text-5xl sm:text-4xl font-bold tracking-tight text-white">
                                    <div>Verdaderas oportunidades laborales </div>
                                    <div className="md:pl-0 sm:pl-0 lg:text-left sm:text-center md:text-center lg:pl-48">para apasionados del deporte</div>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <Swiper
                        modules={[Navigation, A11y, Autoplay]}
                        slidesPerView={1}
                        loop={true}
                        navigation
                        autoplay={{
                            delay: 10000,
                            disableOnInteraction: false
                        }}
                    >

                        {peoples.map((people => (
                            <SwiperSlide key={ people.name }>
                                <div className="mx-auto pb-20">
                                    <div className="mx-auto grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                                        <div className="lg:ml-auto lg:pl-4 lg:pt-4 content-center">
                                            <div className="lg:max-w-lg md:text-center 2xl:text-left xl:text-left lg:text-left xs:text-center">
                                                <h2 className="text-base font-semibold leading-7 text-white">Nuestros empleados, nuestras historias</h2>
                                                <p className="mt-2 text-3xl font-black tracking-tight text-white">{`La historia de ${people.name}`}</p>
                                                <p className="mt-6 text-lg leading-8 text-white">
                                                    { people.bio }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-start justify-center lg:order-first">
                                            <img
                                                src={ people.imageUrl }
                                                alt="Product screenshot"
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )))}
                    </Swiper>
                </div>
            </section>
        </>

    )
}