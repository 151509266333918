interface Props {
    image?: string,
    title?: string
}

export const BannerTitle = ({ image, title }: Props) => {
    return (
        <div className="bg-dkt-bg-alternative">
            <div className="relative isolate overflow-hidden">
                <img
                    src={ image }
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full"
                />
                <div className="mx-auto xl:max-w-7xl lg:max-w-6xl md:max-w-4xl sm:max-w-2xl py-32 sm:py-48 lg:py-56 pl-4">
                    <div className="text-left">
                        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                            { title }
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}