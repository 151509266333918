import { useContext } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom";
import { LoginResponse, postLogin } from "../selectors/login";
import { putRegister, postRegister, postPasswordReset, postNewPass } from "../selectors/register";
import { clearStorage } from "../utils/functions";
import SessionContext from "../contexts/SessionContext";
import { decodeToken } from "react-jwt";
import toast from "react-hot-toast";

export interface TokenUser {
    email: string
    exp: number
    firstName: string
    iat: number
    id: string
    lastName: string
    role: string
    sub: string
}

export const TOKEN_STORAGE_KEY = "token"

const useSession = () => {

    const queryClient = useQueryClient()

    const [token, setToken] = useContext(SessionContext)
    const navigate = useNavigate();

    const { mutate: login, isError: errorLogin, isLoading: loadingLogin } = useMutation(postLogin, {
        onSuccess: ({ token }: LoginResponse) => {
            localStorage.setItem(TOKEN_STORAGE_KEY, token)
            setToken(token)
            navigate(-1)
        }
    })

    const { mutate: register, isError: errorRegister, isLoading: loadingRegister } = useMutation(postRegister, {
        onSuccess: ({ token }: LoginResponse) => {
            localStorage.setItem(TOKEN_STORAGE_KEY, token)
            setToken(token)
            toast.success("Registro correcto. Ahora completa tu perfil para que aumentes las opciones de ser reclutado.", { duration: 7000, style: { background: "#3643BA", color: "white" } })
            navigate('/myProfile')
        },
        onError: (error) =>{
            console.log(error)
            toast.error("No fue posible realizar tu registro, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        }
    })

    const { mutate: update, isError: errorUpdate, isLoading: loadingUpdate } = useMutation(putRegister, {
        onSuccess: ({ token }: LoginResponse) => {
            localStorage.setItem(TOKEN_STORAGE_KEY, token)
            setToken(token)
            queryClient.invalidateQueries(["Aplicant"])
            toast.success("Tus datos personales han sido actualizados", { style: { background: "#3643BA", color: "white" } })
        },
        onError: (error) =>{
            console.log(error)
            toast.error("No fue posible actualizar tus datos personales, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        }
    })

    const { mutate: resetPassword, isError: errorResetPassword, isLoading: loadingResetPassword } = useMutation(postPasswordReset, {
        onSuccess: () => {
            toast.success("Se ha enviado una nueva contraseña a tu correo", { style: { background: "#3643BA", color: "white" } })
        },
        onError: (error) =>{
            console.log(error)
            toast.error("No fue posible actualizar tu contraseñas, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        }
    })

    const { mutate: newPass, isError: errorNewPassword, isLoading: loadingNewPassword } = useMutation(postNewPass, {
        onSuccess: () => {
            toast.success("Se ha actualizado la contraseña.", { style: { background: "#3643BA", color: "white" } })
        },
        onError: (error) =>{
            console.log(error)
            toast.error("No fue posible actualizar tu contraseñas, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        }
    })

    const logout = () => {
        queryClient.invalidateQueries()
        clearStorage()
        setToken(undefined)
        navigate("/")
    }

    const getBasicUser = (): TokenUser => {
        const decoded = decodeToken(token) as TokenUser
        return decoded
    }
    
    const currentUser = getBasicUser() as TokenUser | undefined

    return { login, errorLogin, loadingLogin, 
        logout, register, errorRegister, loadingRegister, 
        update, errorUpdate, loadingUpdate, currentUser, 
        resetPassword, errorResetPassword, loadingResetPassword,
        newPass, errorNewPassword, loadingNewPassword
    }
}

export default useSession