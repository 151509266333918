import PositionDetails from "../interfaces/PositionDetails";
import Positions from "../interfaces/Positions";
import PositionsDashboard from "../interfaces/PositionsDashboard";
import PositionsSubscribed from "../interfaces/PositionsSubscribed";
import { recluiterApi } from "../utils/axios";

export const getPositions = (): Promise<Positions[]> => {
    return recluiterApi.get<Array<Positions>>(`/positions`).then(res => res.data)
}

export const getPositionId = (opportunitiesId: string | undefined): Promise<PositionDetails> => {
    return recluiterApi.get<PositionDetails>(`/positions/getPositionById/${opportunitiesId}`).then(res => res.data)
}

export const getPositionsDashboard = (): Promise<PositionsDashboard> => {
    return recluiterApi.get<PositionsDashboard>(`/place-positions/getPositionsData/dashboard`).then(res => res.data)
}

export const getPositionsSubscribed = (idCategorie: string | undefined): Promise<PositionsSubscribed[]> => {
    return recluiterApi.get<Array<PositionsSubscribed>>(`/positions/getPositionsWithSuscribtions/subscribed/${idCategorie}`).then(res => res.data)
}