import { ShoppingBagIcon, TruckIcon, BriefcaseIcon } from "@heroicons/react/outline"
import { Footer } from "../../ui/Footer"
import { NavLink } from "react-router-dom"
import { BannerTitle } from "../../ui/BannerTitle"
import { useQuery } from "@tanstack/react-query"
import { getCategoriesAll } from "../../../../selectors/categories"

export const Vacancies = () => {

    const { data } = useQuery(
        ["Categories"],
        getCategoriesAll
    );

    return (
        <>
            <BannerTitle image="/assets/images/v2/vacantes/Pagina-Vacantes-3.jpg" title="Vacantes" />

            <div className="bg-dkt-brand-blue">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8 max-w-7xl px-4 sm:px-6 mx-auto py-32">
                    <div className="md:max-w-2xl lg:col-span-6 2xl:text-left xl:text-left lg:text-left md:text-center sm:text-center text-center content-center mx-auto">
                        <div>
                            <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-white font-bold">Descubre</h1>
                            <h2 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-white font-bold">nuestras</h2>
                            <h2 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-white font-bold">diferentes</h2>
                            <h2 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-dkt-green-01 font-bold">profesiones</h2>
                        </div>
                    </div>
                    <div className="relative sm:max-w-lg sm:mx-auto lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex">
                        <div className="w-full grid justify-center">
                            {
                                data?.map((categories) => (
                                    <div className="sm:flex justify-center" key={categories.idCategory}>
                                        <div className="text-sm">
                                            <NavLink className="relative flex items-center text-left w-72 text-white rounded-full px-4 py-1.5 ring-1 hover:ring-white/20 ring-white"
                                                to={`${categories.idCategory}`}
                                                state = { categories }
                                            >
                                                {
                                                    (categories.name === "ADMINISTRATIVA" && <BriefcaseIcon className="w-5 h-5 mr-2 text-dkt-green-01" aria-hidden="true" />) ||
                                                    (categories.name === " LOGÍSTICA" && <TruckIcon className="w-5 h-5 mr-2 text-dkt-green-01" aria-hidden="true" />) ||
                                                    (categories.name === "RETAIL/TIENDAS" && <ShoppingBagIcon className="w-5 h-5 mr-2 text-dkt-green-01" aria-hidden="true" />)
                                                }
                                                <span>{categories.name}</span>
                                                <span aria-hidden="true">&rarr;</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-dkt-bg-alternative">
                <div>
                    <div className="relative isolate overflow-hidden">
                        <img
                            src = "/assets/images/v2/vacantes/Pagina-Vacantes-4.jpg"
                            alt=""
                            className="absolute inset-0 -z-10 w-full object-cover"
                        />
                        <div className="mx-auto max-w-2xl py-28">
                            <div className="text-center py-24">
                                <h1 className="text-5xl font-bold tracking-tight text-white">
                                    ¡Bien hecho, has cruzado la linea de meta!
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}