export const Footer = () => {

    const logoDecathlonColombia = "/assets/images/v2/DKT_Lockup_Primary_White_RGB.png"

    return (
        <footer className="bg-dkt-brand-blue isolate">
            <div className="mx-auto max-w-7xl px-6 py-10 md:flex md:items-center justify-center lg:px-8 gap-10">
                <div className="flex justify-center space-x-6 md:order-2">
                    <p className="text-center text-3xl leading-5 text-white">
                        Trabaja con nosotros
                    </p>
                </div>
                <div className="mt-8 md:order-1 md:mt-0">
                    <img className="h-14 w-auto mx-auto" src={logoDecathlonColombia} alt="" />
                </div>
            </div>
        </footer>
    )
}