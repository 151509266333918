import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'
import { TrashIcon, FireIcon, } from '@heroicons/react/solid'
import toast from 'react-hot-toast';
import useSession from '../../../../hooks/useSession';
import { getall } from '../../../../selectors/sports';
import { createSportApplicant, removeSportApplicant, sportsApplicant } from '../../../../selectors/applicants';
import { Alerts } from '../../../../components/ui/Alerts';
import { LoadingSpinner } from '../../../../components/ui/LoadingSpinner';


interface Props {
    setSelectedMenuItem?: any;
}

export const FavoriteSports = ({ setSelectedMenuItem }: Props) => {

    const queryClient = useQueryClient();
    const { currentUser } = useSession()

    const { data } = useQuery(["sports"], () => getall())
    const { data: getSportApplicant, isLoading: isLoadingSportApplicant, isError: isErrorSportApplicant } = useQuery(["SportsApplicant"], () => sportsApplicant(currentUser?.id))

    const { mutate: addSportToApplicant } = useMutation(createSportApplicant, {
        onSuccess: (newSport) => {
            getSportApplicant?.push(newSport)
            toast.dismiss()
            toast.success("Has agregado un nuevo deporte.", { style: { background: "#3643BA", color: "white" } })
        },
        onError: ({ error }) => {
            console.log(error)
            toast.dismiss()
            toast.error("No fue posible agregar tu nuevo deporte, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        },
        onMutate: () => {
          toast.loading("Agregando un deporte.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    const { mutate: deleteSportToApplicant } = useMutation(removeSportApplicant, {
        onSuccess: () => {
            queryClient.invalidateQueries(["SportsApplicant"])
            toast.dismiss()
            toast.success("Has eliminado un deporte.", { style: { background: "#3643BA", color: "white" } })
        },
        onError: ({ error }) => {
            console.log(error)
            toast.dismiss()
            toast.error("No fue posible eliminar tu deporte, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        },
        onMutate: () => {
          toast.loading("Eliminando un deporte.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    const handleNextForm = () => {
        if(getSportApplicant!.length > 0){
            setSelectedMenuItem(4)
        }else{
            toast.dismiss()
            toast.error("Por favor ingresa tus deportes favoritos.", { style: { background: "#E3262F", color: "white" } })
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    idApplicant: currentUser?.id,
                    idSport: data && data.length > 0 ? data[0].idSport : ""
                }}
                onSubmit={
                    (values, { resetForm }) => {
                        addSportToApplicant(values)
                        resetForm()
                    }
                }
                validationSchema={
                    Yup.object({
                        idSport: Yup.string()
                            .required('Este campo es obligatorio'),
                    })
                }
            >
                {
                    () => (
                        <Form className="space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto max-w-7xl mt-16">
                            {data && data.length > 0 ?   
                                <div className="divide-y divide-grey-dkt-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                                    <div>
                                        <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">DEPORTES PREFERIDOS</h3>
                                        <p className="mt-1 text-lg">Practicas algún deporte?</p>
                                    </div>
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                            <label htmlFor="idSport" className="block text-lg font-medium">
                                                Deportes
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <Field
                                                    as="select"
                                                    id="idSport"
                                                    name="idSport"
                                                    className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                >
                                                    {
                                                        data?.map((sport) => (
                                                            <option key={sport.idSport} value={sport.idSport}>{sport.name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Alerts title={"Sin idiomas"} content={"No hay deportes registrados en nuestro sistema."} />
                            }
                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        onClick={() => setSelectedMenuItem(2)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                    >
                                        Anterior
                                    </button>
                                   {data && data.length > 0  &&  
                                        <button
                                            type="submit"
                                            className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                        >
                                            Guardar
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        onClick={() => handleNextForm()}
                                        className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                    >
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>

            <div>
                <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">MIS DEPORTES</h3>
                {
                    isLoadingSportApplicant ? <LoadingSpinner /> :
                        isErrorSportApplicant ? <Alerts title={"Error al cargar"} content={"Ha ocurrido un error inesperado, intenta cargar de nuevo el sitio web"} /> :
                            getSportApplicant.length < 1 ? <Alerts title={"Sin deportes"} content={"Agrega los deportes que practicas, asi aumentaras las opciones de ser reclutado"} /> :
                                <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                                    {getSportApplicant?.map((sport) => (
                                        <li key={sport.idSport} className="col-span-1 flex rounded-md shadow-xl">
                                            <div
                                                className={'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md bg-dkt-brand-blue'}
                                            >
                                                <FireIcon className="h-5 w-5" aria-hidden="true" />
                                            </div>
                                            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-grey-dkt-200 bg-white">
                                                <div className="flex-1 truncate px-4 py-2 text-sm">
                                                    <span className="font-medium text-grey-dkt-900 hover:text-grey-dkt-600">
                                                        {data?.find(element => element.idSport === sport.idSport)?.name}
                                                    </span>

                                                </div>
                                                <div className="flex-shrink-0 pr-2">
                                                    <button
                                                        onClick={() => deleteSportToApplicant(sport.idSportApplicant)}
                                                        type="button"
                                                        className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-grey-dkt-400 hover:text-grey-dkt-500 focus:outline-none focus:ring-2 focus:ring-purple-dkt-500 focus:ring-offset-2"
                                                    >
                                                        <span className="sr-only">Open options</span>
                                                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                }
            </div>
        </>
    )
}
