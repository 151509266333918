import React, { useEffect, useState, createContext } from "react";
import { TOKEN_STORAGE_KEY } from "../hooks/useSession";
import { isExpired } from "react-jwt";

const SessionContext = createContext<undefined | any>(undefined)

interface SessionContextProviderProps {
    children: React.ReactNode
}

export interface TokenUser {
    email: string
    exp: number
    firstName: string
    iat: number
    id: string
    lastName: string
    role: string
    sub: string
}

export const SessionContextProvider = ({children}: SessionContextProviderProps) => {
    const [token, setToken] = useState<string | null>(() => localStorage.getItem(TOKEN_STORAGE_KEY))

    useEffect(() => {
        if (!token) return
        if (isExpired(token)) {
            localStorage.removeItem(TOKEN_STORAGE_KEY)
            setToken(null)
        }
    }, [token])

    return (
        <SessionContext.Provider value={[token, setToken]}>
            {children}
        </SessionContext.Provider>
    )
}

export default SessionContext