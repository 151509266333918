import { Link, NavLink, useLocation } from 'react-router-dom'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import { routes } from './routes'
import { Fragment, useState } from 'react'
import useSession from '../../hooks/useSession'
import { isSignedIn } from '../../utils/isSignedIn'

const navigation = [
    { name: '¿Quienes somos?', href: '#' },
    { name: '¿Por que nosotros?', href: '#' },
    { name: 'Vacantes', href: '#' },
]


export const Navbar = () => {

    const { logout } = useSession()
    const { currentUser } = useSession()
    const user = {
        name: currentUser?.firstName + ' ' + currentUser?.lastName,
        email: currentUser?.email
    }

    const location = useLocation();
    const currentPath = location.pathname;

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const logoDecathlonColombia = "/assets/images/v2/DKT_Lockup_Primary_Blue_RGB.png"

    return (
        <>
            <header className="bg-white py-4">
                <nav className="mx-auto flex 2xl:px-24 2xl:max-w-full xl:max-w-7xl lg:max-w-6xl md:max-w-4xl sm:max-w-2xl items-center justify-between gap-x-6 py-6 px-4" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 py-1.5">
                            <span className="sr-only">Decathlon Colombia</span>
                            <img className="h-14 w-auto" src={logoDecathlonColombia} alt="" />
                        </Link>
                    </div>

                    {
                        currentPath !== '/' &&
                        <div className="hidden lg:flex lg:gap-x-12">
                            {routes.map((item) => (
                                item.navBarNavigation &&
                                <NavLink key={item.name} to={item.path}
                                    className={({ isActive }) => "text-base font-bold leading-6 " + (isActive ? 'text-grey-dkt-300' : 'text-grey-dkt-900')}>
                                    {item.name}
                                </NavLink>
                            ))}
                        </div>
                    }
                    {
                        isSignedIn() ?
                            <div className="ml-4 sm:flex flex items-center md:ml-6 p-4 hidden sm:block">
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="max-w-xs bg-grey-dkt-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-grey-dkt-800 focus:ring-white">
                                            <span className="sr-only">Open user menu</span>
                                            <div className="h-10 w-10 text-dkt-green-01 text-lg font-bold bg-dkt-brand-blue rounded-full flex justify-center items-center">
                                                {user.name.charAt(0)}
                                            </div>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {routes.map((item) => (
                                                item.userNavigation &&
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <NavLink
                                                            to={item.path}
                                                            className={() => 'block px-4 py-2 text-sm text-grey-dkt-700 ' + (active ? 'bg-grey-dkt-100' : '')}
                                                        >
                                                            {item.name}
                                                        </NavLink>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                            <button onClick={logout} type='button' className='block px-4 py-2 text-sm text-grey-dkt-700 hover:bg-grey-dkt-100'>
                                                CERRAR SESIÓN
                                            </button>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            :
                            <div className="flex flex-1 items-center justify-end gap-x-6">
                                <NavLink to="register" className={({ isActive }) => "text-base font-bold leading-6 " + (isActive ? 'text-grey-dkt-300' : 'text-grey-dkt-900')}>
                                    Regístrate
                                </NavLink>
                                <Link
                                    to="login"
                                    className="rounded-full bg-dkt-brand-blue px-6 py-2 text-base font-bold text-dkt-green-01 shadow-sm hover:bg-grey-dkt-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-dkt-600"
                                >
                                    Inicia sesión
                                </Link>
                            </div>
                    }
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-grey-dkt-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </nav>
                <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10" />
                    <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-grey-dkt-900/10">
                        <div className="flex items-center gap-x-6">
                            <Link to="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    className="h-8 w-auto"
                                    src="https://tailwindui.com/img/logos/mark.svg?color=grey-dkt&shade=600"
                                    alt=""
                                />
                            </Link>
                            <Link
                                to="#"
                                className="ml-auto rounded-md bg-grey-dkt-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-grey-dkt-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-dkt-600"
                            >
                                Sign up
                            </Link>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-grey-dkt-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-grey-dkt-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-grey-dkt-900 hover:bg-grey-dkt-50"
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <Link
                                        to="login"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-grey-dkt-900 hover:bg-grey-dkt-50"
                                    >
                                        Inicia Sesión
                                    </Link>
                                    <Link
                                        to="register"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-grey-dkt-900 hover:bg-grey-dkt-50"
                                    >
                                        Regístrate
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </header>
        </>
    )
}