import { useLocation, useParams } from "react-router-dom";
import { BannerTitle } from "../../ui/BannerTitle"
import { FlipCard } from "../../ui/FlipCard"
import { Footer } from "../../ui/Footer"
import { useQuery } from "@tanstack/react-query";
import { getPositionsSubscribed } from "../../../../selectors/positions";
import { LoadingSpinner } from "../../../../components/ui/LoadingSpinner";
import { Alerts } from "../../../../components/ui/Alerts";

export const VacanciesByCategories = () => {

    const location = useLocation();
    console.log(location.state)

    const { catId } = useParams()
    const { data, isLoading, isError } = useQuery(["Positions", catId], () => getPositionsSubscribed(catId))

    const categorie: any = location.state;

    const image = '/assets/images/decathlon-generica.jpeg';

    const bannerCategory = {
        ADMINISTRATIVO: "/assets/images/v2/vacantes/BannerVacantes-06.jpg",
        LOGISTICA: "/assets/images/v2/vacantes/BannerVacantes-03.jpg",
        RETAIL: "/assets/images/v2/vacantes/BannerVacantes-04.jpg"
    }

    return (
        <>

            <BannerTitle
                image={`${ (categorie?.name === "ADMINISTRATIVA" && bannerCategory.ADMINISTRATIVO) ||
                    (categorie?.name === " LOGÍSTICA" && bannerCategory.LOGISTICA) ||
                    (categorie?.name === "RETAIL/TIENDAS" && bannerCategory.RETAIL)}`}
                title={`${(categorie?.name === "ADMINISTRATIVA" && categorie?.name) ||
                    (categorie?.name === " LOGÍSTICA" && categorie?.name) ||
                    (categorie?.name === "RETAIL/TIENDAS" && categorie?.name)}`}
            />

            <div className="2xl:max-w-7xl xl:max-w-7xl lg:max-w-6xl md:max-w-4xl sm:max-w-2xl mx-auto lg:text-left px-4 py-12">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {categorie.description}
                </p>
            </div>

            <div className="mx-auto mt-10">
                <div>
                    {
                        isLoading ? (< LoadingSpinner />) :
                            isError ? (<Alerts title={"A ocurrido un error inesperado"} content={"Inténtalo de nuevo o comunícate con el proveedor del servicio"}></Alerts>) :
                                data.length === 0 ?
                                    <Alerts title={"Sin vacantes"} content={"De momento no tenemos vacantes disponibles en esta categoría"}></Alerts> :
                                    (
                                        <ul
                                            role="list"
                                            className="mx-auto grid lg:mx-0 lg:max-w-none bg-dkt-bg-alternative sm:grid-cols-2 lg:grid-cols-4"
                                        >
                                            {data.map((vacancie) => (
                                                <li>
                                                    <FlipCard title={vacancie.title} image={image} content={vacancie.title} vacancie={vacancie} />
                                                </li>
                                            ))}
                                        </ul>
                                    )
                    }
                </div>
            </div>

            <Footer />
        </>

    )
}