import { BannerTitle } from "../../../ui/BannerTitle"
import { Footer } from "../../../ui/Footer"
import { Benefits, VacancyBannerWd } from "."

export const WhyWe = () => {
    return (
        <>
            {/** ¿Quiénes somos? */}
            <BannerTitle image="/assets/images/v2/porQueNosotros/PorqueNosotros-ST_banner1.jpg" title="¿Por que nosotros?" />

            <main>
                <div className="lg:grid lg:grid-cols-12 lg:gap-8 max-w-7xl px-4 sm:px-6 mx-auto">
                    <div className="md:max-w-2xl lg:col-span-6 2xl:text-left xl:text-left lg:text-left md:text-center sm:text-center text-center content-center mx-auto">
                        <div>
                            <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl text-black pb-6 font-bold">
                                <span className="grid">¡Atrévete,</span>
                                <span>confiamos en ti!</span>
                            </h1>
                            <h2 className="text-black">
                                <p>
                                    En Decathlon, creemos que el deporte es mucho más que un juego; es una pasión que mueve al mundo y transforma vidas.
                                    Buscamos personas entusiastas y talentosas que compartan esta visión y quieran formar parte de un equipo que transmite
                                    la excelencia, la innovación, y el compromiso.
                                </p>
                                <p className="pt-3">
                                    Aquí, confiamos en ti y en lo que puedes aportar. Estamos buscando personas que se atrevan a desafiar los límites,
                                    a innovar y a contribuir al éxito colectivo. Si estás listo para asumir el reto y formar parte de un
                                    equipo que realmente marca la diferencia, ¡queremos conocerte!
                                </p>
                            </h2>
                        </div>
                        {/* <div className="mt-3 text-lg text-grey-dkt-500 sm:mt-5 sm:text-xl lg:text-lg"
                            dangerouslySetInnerHTML={{ __html: aboutUsContent?.aboutUs ?? '' }} /> */}
                    </div>
                    <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex">
                    {/* <div className="md:max-w-2xl lg:col-span-5 2xl:text-left xl:text-left lg:text-left md:text-center sm:text-center text-center content-center mx-auto"> */}
                        <div className="flex-1 w-full content-center">
                            <img
                                src="/assets/images/v2/porQueNosotros/PorqueNosotros-02.jpg"
                                alt="Product screenshot"
                                className="mx-auto object-cover h-5/6"
                            />
                        </div>
                    </div>
                </div>
            </main >

            <Benefits />

            <VacancyBannerWd />

            <Footer />
        </>
    )
}