import { Disclosure } from '@headlessui/react'

import { ChevronDownIcon } from '@heroicons/react/outline'

import {
    CheckCircleIcon
} from '@heroicons/react/solid'
import { ApplicantionsEvents } from '../../../../interfaces/Applications'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface Props {
    historyApplicant: ApplicantionsEvents[]
}

export const HistoryMyNominations = ({ historyApplicant }: Props) => {
    return (
        <section aria-labelledby="activity-title">
            <div>
                <div className="divide-y divide-grey-dkt-200">
                    <dl className="space-y-6 divide-y divide-gray-200">
                        <Disclosure as="div" className="pt-4 text-lg font-medium text-dkt-brand-blue">
                            {({ open }) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start">
                                            <span className="font-medium text-lg">Historial de la candidatura</span>
                                            <span className="ml-6 h-7 flex items-center">
                                                <ChevronDownIcon
                                                    className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <div className="pt-6">
                                            {/* Activity feed*/}
                                            <div className="flow-root">
                                                <ul className="-mb-8">
                                                    {historyApplicant.map((item, itemIdx) => (
                                                        <li key={item.idEvent}>
                                                            <div className="relative pb-8">
                                                                {itemIdx !== historyApplicant.length - 1 ? (
                                                                    <span
                                                                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-grey-dkt-300"
                                                                        aria-hidden="true"
                                                                    />
                                                                ) : null}
                                                                <div className="relative flex items-start space-x-3">
                                                                    {
                                                                        <>
                                                                            <div className='mt-1'>
                                                                                <div className="relative px-1">
                                                                                    <div className="h-8 w-8 bg-grey-dkt-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                                        {/* {icon(item.type)} */}
                                                                                        <CheckCircleIcon className="h-5 w-5 text-dkt-brand-blue" aria-hidden="true" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="min-w-0 flex-1 py-1.5">
                                                                                <div>
                                                                                    <div className="text-sm">
                                                                                        <span className="font-medium text-grey-dkt-900">
                                                                                            {item.name}
                                                                                        </span>
                                                                                    </div>
                                                                                    <p className="mt-0.5 text-sm text-grey-dkt-900">{item.eventdate}</p>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </dl>
                </div>
            </div>
        </section>
    )
}
