import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'
import { StarIcon, TranslateIcon, TrashIcon } from '@heroicons/react/solid'
import toast from 'react-hot-toast';
import useSession from '../../../../hooks/useSession';
import { createLanguageApplicant, languagesApplicant, removeLanguageApplicant } from '../../../../selectors/applicants';
import { Alerts } from '../../../../components/ui/Alerts';
import { LoadingSpinner } from '../../../../components/ui/LoadingSpinner';
import { getall } from '../../../../selectors/languages';

interface Props {
    setSelectedMenuItem?: any;
}

export const Languages = ({ setSelectedMenuItem }: Props) => {

    const languagesLevel = [
        {
            name: "Básico",
            level: 1
        },
        {
            name: "Básico - intermedio",
            level: 2
        },
        {
            name: "Intermedio",
            level: 3
        },
        {
            name: "Intermedio - avanzado",
            level: 4
        },
        {
            name: "Avanzado",
            level: 5
        }
    ]

    const { currentUser } = useSession()
    const queryClient = useQueryClient();

    const { data } = useQuery(["languages"], () => getall())
    const { data: getLanguagesApplicant, isLoading: isLoadingLanguageApplicant, isError: isErrorLanguageApplicant } = useQuery(["languagesApplicant"], () => languagesApplicant(currentUser?.id))

    const { mutate: addLanguageToApplicant } = useMutation(createLanguageApplicant, {
        onSuccess: (newLanguage) => {
            getLanguagesApplicant?.push(newLanguage)
            toast.dismiss()
            toast.success("Has agregado un nuevo lenguaje.", { style: { background: "#3643BA", color: "white" } })
        },
        onError: ({ error }) => {
            console.log(error)
            toast.dismiss()
            toast.error("No fue posible agregar tu nuevo lenguaje, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        },
        onMutate: () => {
          toast.loading("Agregando un lenguaje.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    const { mutate: deleteLanguageToApplicant } = useMutation(removeLanguageApplicant, {
        onSuccess: () => {
            queryClient.invalidateQueries(["languagesApplicant"])
            toast.dismiss()
            toast.success("Has eliminado un lenguaje.", { style: { background: "#3643BA", color: "white" } })
        },
        onError: ({ error }) => {
            console.log(error)
            toast.dismiss()
            toast.error("No fue posible eliminar tu lenguaje, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        },
        onMutate: () => {
          toast.loading("Eliminando un lenguaje.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    const handleNextForm = () => {
        if(getLanguagesApplicant!.length > 0){
            setSelectedMenuItem(3)
        }else{
            toast.dismiss()
            toast.error("Por favor ingresa los idiomas que dominas.", { style: { background: "#E3262F", color: "white" } })
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    idApplicant: currentUser?.id,
                    idLanguage: data && data.length > 0 ? data[0].idLanguage : "",
                    speaking: 1,
                    listening: 1,
                    writing: 1,
                    reading: 1
                }}
                onSubmit={
                    (values, { resetForm }) => {
                        addLanguageToApplicant(values)
                        resetForm()
                    }
                }
                validationSchema={
                    Yup.object({
                        idLanguage: Yup.string()
                            .required('Este campo es obligatorio'),
                        speaking: Yup.number()
                            .required('Este campo es obligatorio'),
                        listening: Yup.number()
                            .required('Este campo es obligatorio'),
                        writing: Yup.number()
                            .required('Este campo es obligatorio'),
                        reading: Yup.number()
                            .required('Este campo es obligatorio')
                    })
                }
            >
                {
                    () => (
                        <Form className="space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto max-w-7xl mt-16">
                            {data && data.length > 0 ?
                                <div className="divide-y divide-grey-dkt-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                                    <div>
                                        <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">IDIOMAS</h3>
                                        <p className="mt-1 text-lg">Que idiomas dominas y a que nivel</p>
                                    </div>

                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                            <label htmlFor="idLanguage" className="block text-lg font-medium">
                                                Idioma
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <Field
                                                    as="select"
                                                    id="idLanguage"
                                                    name="idLanguage"
                                                    className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                >
                                                    {
                                                        data?.map((language) => (
                                                            <option key={language.idLanguage} value={language.idLanguage}>{language.name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                        <div className="sm:col-span-3 sm:pt-5">
                                            <label htmlFor="speaking" className="block text-lg font-medium">
                                                Habla
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    as="select"
                                                    id="speaking"
                                                    name="speaking"
                                                    className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                >
                                                    {
                                                        languagesLevel.map((levelLanguage) => (
                                                            <option key={levelLanguage.level} value={levelLanguage.level}>{levelLanguage.name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3 sm:pt-5">
                                            <label htmlFor="listening" className="block text-lg font-medium">
                                                Escucha
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    as="select"
                                                    id="listening"
                                                    name="listening"
                                                    className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                >
                                                    {
                                                        languagesLevel.map((levelLanguage) => (
                                                            <option key={levelLanguage.level} value={levelLanguage.level}>{levelLanguage.name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                        <div className="sm:col-span-3 sm:pt-5">
                                            <label htmlFor="writing" className="block text-lg font-medium">
                                                Escritura
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    as="select"
                                                    id="writing"
                                                    name="writing"
                                                    className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                >
                                                    {
                                                        languagesLevel.map((levelLanguage) => (
                                                            <option key={levelLanguage.level} value={levelLanguage.level}>{levelLanguage.name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3 sm:pt-5">
                                            <label htmlFor="reading" className="block text-lg font-medium">
                                                Lectura
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    as="select"
                                                    id="reading"
                                                    name="reading"
                                                    className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                >
                                                    {
                                                        languagesLevel.map((levelLanguage) => (
                                                            <option key={levelLanguage.level} value={levelLanguage.level}>{levelLanguage.name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Alerts title={"Sin idiomas"} content={"No hay idiomas registrados en nuestro sistema."} />
                            }
                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        onClick={() => setSelectedMenuItem(1)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                    >
                                        Anterior
                                    </button>
                                    {data && data.length > 0  && 
                                        <button
                                            type="submit"
                                            className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                        >
                                            Guardar
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        onClick={() => handleNextForm()}
                                        className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                    >
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
            <div>
                <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">MIS IDIOMAS</h3>
                {
                    isLoadingLanguageApplicant ? <LoadingSpinner /> : isErrorLanguageApplicant ?
                        <Alerts title={"Error al cargar"} content={"Ha ocurrido un error inesperado, intenta cargar de nuevo el sitio web"} /> :
                        getLanguagesApplicant.length < 1 ? <Alerts title={"Sin idiomas"} content={"Agrega los idiomas que dominas, asi aumentaras las opciones de ser reclutado"} /> :
                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                            {
                                getLanguagesApplicant?.map((language) => (
                                    <div key={language.idLanguage} className="col-span-1 flex rounded-md shadow-xl">
                                        <div
                                            className={'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md bg-dkt-brand-blue'}
                                        >
                                            <TranslateIcon className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-grey-dkt-200 bg-white">
                                            <div className="flex-1 truncate px-4 py-2 text-sm">
                                                <span className="mt-1 text-base font-semibold tracking-tight text-dkt-brand-blue">
                                                    {data?.find(element => element.idLanguage === language.idLanguage)?.name}
                                                </span>
                                                <dd className="truncate text-sm font-medium text-grey-dkt-500 sm:flex">Escritura:  {language.writing}/5 <span className="ml-2 flex items-center">{Array.from({ length: language.writing }).map((_, index) => <StarIcon key={index} className="h-4 text-yellow-dkt-400 inline" />)}</span></dd>
                                                <dd className="truncate text-sm font-medium text-grey-dkt-500 sm:flex">Lectura:  {language.reading}/5 <span className="ml-2 flex items-center">{Array.from({ length: language.reading }).map((_, index) => <StarIcon key={index} className="h-4 text-yellow-dkt-400 inline" />)}</span></dd>
                                                <dd className="truncate text-sm font-medium text-grey-dkt-500 sm:flex">Escucha: {language.listening}/5 <span className="ml-2 flex items-center">{Array.from({ length: language.listening }).map((_, index) => <StarIcon key={index} className="h-4 text-yellow-dkt-400 inline" />)}</span></dd>
                                                <dd className="truncate text-sm font-medium text-grey-dkt-500 sm:flex">Habla: {language.speaking}/5 <span className="ml-2 flex items-center">{Array.from({ length: language.speaking }).map((_, index) => <StarIcon key={index} className="h-4 text-yellow-dkt-400 inline" />)}</span></dd>
                                            </div>
                                            <div className="flex-shrink-0 pr-2">
                                                <button
                                                    onClick={() => deleteLanguageToApplicant(language.idLanguageApplicant)}
                                                    type="button"
                                                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-grey-dkt-400 hover:text-grey-dkt-500 focus:outline-none focus:ring-2 focus:ring-purple-dkt-500 focus:ring-offset-2"
                                                >
                                                    <span className="sr-only">Open options</span>
                                                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </dl>
                }
            </div>
        </>
    )
}
