import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import useSession from '../../../../hooks/useSession';
import { LoadingSpinner } from '../../../../components/ui/LoadingSpinner';
import { Alerts } from '../../../../components/ui/Alerts';
import { getById } from '../../../../selectors/applicants';
import { getReferredAll } from '../../../../selectors/referred';

interface Props {
    setSelectedMenuItem?: any;
}

export const PersonalInformation = ({ setSelectedMenuItem }: Props) => {
    const [referredEmailFieldVisible, setReferredEmailFieldVisible] = useState(false);
    const { currentUser, update } = useSession()
    const { data: applicant, isLoading, isError } = useQuery(["Aplicant"], () => getById(currentUser?.id))
    const { data: dataReferred } = useQuery(["Referred"], () => getReferredAll())

    console.log(dataReferred)
    console.log(applicant)
    
    useEffect(() => {
        if(applicant && dataReferred)
            handleValidateEmailField(applicant.idReferred)    
    }, [applicant, dataReferred]);

    const handleValidateEmailField = (idReferred?: string) => {
        let referredEmailFieldVisible = idReferred === dataReferred?.find(referred => referred.isPrincipal == true)?.idReferred;
        setReferredEmailFieldVisible(referredEmailFieldVisible);
    }

  return (
    <>
    {
      isLoading ? < LoadingSpinner/> :
      isError ? <Alerts title={"Error al cargar"} content={"Ha ocurrido un error inesperado, intenta cargar de nuevo el sitio web"} /> : 
    
      <Formik
            enableReinitialize={true}
            initialValues={{
                idApplicant: currentUser?.id,
                firstName: applicant ? applicant.firstName : '',
                lastName: applicant ? applicant.lastName : '',
                document: applicant ? applicant.document : '',
                typeDocument: 'CédulaCiudadanía',
                email: applicant ? applicant.email : '',
                phone: applicant ? applicant.phone : '',
                address: applicant ? applicant.address : '',
                city: applicant ? applicant.city : '',
                birthDate: applicant ? applicant.birthDate : '',
                idReferred: applicant ? applicant.idReferred : '',
                referredEmail: applicant ? applicant.referredEmail : ''
            }}
            onSubmit={
                (values) => {
                    if(!referredEmailFieldVisible)
                    values.referredEmail=''
                    
                    update(values);
                    setSelectedMenuItem(1);
                }
            }
            validationSchema={
                Yup.object({
                    firstName: Yup.string()
                        .required('Este campo es obligatorio'),
                    lastName: Yup.string()
                        .required('Este campo es obligatorio'),
                    document: Yup.string()
                        .required('Este campo es obligatorio'),
                    typeDocument: Yup.string()
                        .required('Este campo es obligatorio'),
                    email: Yup.string()
                        .email('Escribe un formato de correo valido')
                        .required('Este campo es obligatorio'),
                    phone: Yup.string()
                        .min(7, 'Ingresa al menos 7 dígitos')
                        .max(10, 'Ingresa solo hasta 10 dígitos')
                        .required('Este campo es obligatorio'),
                    address: Yup.string()
                        .required('Este campo es obligatorio'),
                    city: Yup.string()
                        .required('Este campo es obligatorio'),
                    birthDate: Yup.string()
                        .required('Este campo es obligatorio'),
                    idReferred: Yup.string()
                        .required('Este campo es obligatorio'),
                    referredEmail: referredEmailFieldVisible ?  
                      Yup.string()
                        .required('Este campo es obligatorio')
                        .matches(/^[A-Za-z0-9._%+-]+@decathlon\.com$/, 'Este no es un correo Decathlon')
                      :
                      Yup.string(),
                })
            }>
            {
                ({ setFieldValue, values }) => (
                    <Form  onClick={() => handleValidateEmailField(values.idReferred)} className="space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto max-w-7xl mt-16">
                        <div className="space-y-8 divide-y divide-grey-dkt-200 sm:space-y-5">
                        
                            <div className="space-y-6 sm:space-y-5">
                                <div>
                                    <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">INFORMACIÓN PERSONAL</h3>
                                    <p className="mt-1 text-lg">Asegúrate de que los datos que ingreses sean correctos para poder contactarte.</p>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="firstName" className="block text-lg font-medium">
                                            Nombres
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="firstName" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="lastName" className="block text-lg font-medium">
                                            Apellidos
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="lastName" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="typeDocument" className="block text-lg font-medium">
                                            Tipo de documento
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                as="select"
                                                id="typeDocument"
                                                name="typeDocument"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            >
                                                <option value={'CédulaCiudadanía'}>Cédula de ciudadanía</option>
                                                <option value={'CédulaExtranjería'}>Cédula de extranjería </option>
                                                <option value={'Pasaporte'}>Pasaporte</option>
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="document" className="block text-lg font-medium">
                                            Número de documento
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="document"
                                                id="document"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="document" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>

                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                        <label htmlFor="birthDate" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                            Fecha de nacimiento
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <Field
                                                type="date"
                                                id="birthDate"
                                                name="birthDate"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="birthDate" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                        <label htmlFor="email" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                            Correo electrónico
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <Field
                                                disabled
                                                id="email"
                                                name="email"
                                                type="email"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="email" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>
                                    <div className="space-y-6 sm:space-y-5">
                                        
                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                            <label htmlFor="idReferred" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                                ¿Eres referido?
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <Field
                                                    as="select"
                                                    id="idReferred"
                                                    name="idReferred"
                                                    
                                                    className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                >
                                                    {dataReferred?.map((referred) => (
                                                        <option key={referred.idReferred} value={referred.idReferred}>{referred.name}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage name="idReferred" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                            </div>
                                        </div>
                                        
                                        {referredEmailFieldVisible &&
                                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                                <label htmlFor="referredEmail" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                                    ¿Cómo es el correo electrónico de quien te refirió?
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <Field
                                                        type="email"
                                                        name="referredEmail"
                                                        id="referredEmail"
                                                        className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                    />
                                                    <ErrorMessage name="referredEmail" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                    <div className="sm:col-span-2 sm:pt-5">
                                        <label htmlFor="city" className="block text-lg font-medium">
                                            Ciudad
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="city"
                                                id="city"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="city" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2 sm:pt-5">
                                        <label htmlFor="address" className="block text-lg font-medium ">
                                            Dirección
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="address"
                                                id="address"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="address" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2 sm:pt-5">
                                        <label htmlFor="phone" className="block text-lg font-medium ">
                                            Teléfono
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="phone" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="pt-5">
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                                >
                                    Actualizar
                                </button>
                            </div>
                        </div>
                        
                    </Form>
                )
            }
        </Formik>
      
    }
    </>
  )
}
