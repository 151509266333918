import { AboutUsContent, OurTeamContent, Position } from "../interfaces/Contents"
import { recluiterApi } from "../utils/axios"

export const getAboutUsContent = (): Promise<AboutUsContent> => {
    return recluiterApi.get<AboutUsContent>(`/aboutUsContent/getAboutUsContent`).then(res => res.data)
}

export const getOurTeamContent = (): Promise<OurTeamContent> => {
    return recluiterApi.get<OurTeamContent>(`/ourTeamContent/getOurTeamContent`).then(res => res.data)
}

export const getPositionContent = (): Promise<Position> => {
    return recluiterApi.get<Position>(`/positionContent/getPositionContent`).then(res => res.data)
}