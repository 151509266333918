import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/outline';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useSession from '../../hooks/useSession';

interface Props {
    open: any,
    setOpen: any
}

export const ModalRecoverPassword = ({ open, setOpen }: Props) => {

    const { resetPassword } = useSession()

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-grey-dkt-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-dkt-brand-blue">
                                        <LockClosedIcon className="h-6 w-6 text-dkt-green-01" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-dkt-brand-blue">
                                            Recupera tu contraseña
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm">
                                                Ingresa el correo electrónico con el que creaste tu cuenta y te enviaremos una nueva. te recomendamos cambiarla una vez ingreses al portal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <Formik
                                        initialValues={{
                                            email: '',
                                        }}
                                        onSubmit={
                                            (values) => {
                                                resetPassword(values)
                                            }
                                        }
                                        validationSchema={
                                            Yup.object({
                                                email: Yup.string()
                                                    .email('Escribe un formato de correo valido')
                                                    .required('Este campo es obligatorio'),
                                            })
                                        }>

                                        {
                                            () => (
                                                <Form className="mt-8 space-y-6">
                                                    <div className="-space-y-px rounded-md shadow-sm">
                                                        <div>
                                                            <label htmlFor="email" className="sr-only">
                                                                Email address
                                                            </label>
                                                            <Field
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                className="relative focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                                placeholder="Correo electrónico"
                                                            />
                                                            <ErrorMessage name="email" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                                        </div>
                                                    </div>

                                                    <div className="flex items-center justify-between">

                                                        <div className="text-sm">
                                                            <button type="button" onClick={() => setOpen(false)} className="font-medium text-blue-dkt-600 hover:text-blue-dkt-500">
                                                                Has recordado tu contraseña?
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <button
                                                            type="submit"
                                                            className="w-full flex justify-center py-2 px-4 rounded-full shadow-sm text-sm font-medium text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-300"
                                                        >
                                                            Recuperar contraseña
                                                        </button>
                                                    </div>
                                                </Form>
                                            )
                                        }
                                    </Formik>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
