export const isEmpty= (element: string | object | null |undefined) => {
    return element === undefined || element === 'undefined'
    || element === null || element === "null" || element === ''
    || (Array.isArray(element) && element.length === 0)
    || (Object.keys(element).length === 0)
}

export const clearStorage = () => {
    sessionStorage.clear();
    localStorage.clear();
}