import { ExclamationIcon } from '@heroicons/react/solid'

interface Props {
    title?: string,
    content?: string,
    event?: string
}

export const Alerts = ({ title, content }: Props) => {

    return (
        <div className="pt-4">
            <div className="rounded-md bg-yellow-dkt-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-yellow-dkt-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-dkt-800">{title}</h3>
                        <div className="mt-2 text-sm text-yellow-dkt-700">
                            <p>{content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
