import { useQuery } from '@tanstack/react-query';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import { useEffect, useState } from 'react';
import { getReferredAll } from '../../../selectors/referred';
import useSession from '../../../hooks/useSession';
import { Alerts } from '../../../components/ui/Alerts';

export const RegisterForm = () => {

    const { register } = useSession()
    const [referredEmailFieldVisible, setReferredEmailFieldVisible] = useState(false);
    const { data: dataReferred } = useQuery(["Referred"], () => getReferredAll());

    useEffect(() => {
        if (dataReferred && dataReferred.length !== 0)
            handleValidateEmailField(dataReferred[0].idReferred)
    }, [dataReferred]);

    const handleValidateEmailField = (idReferred: string) => {
        let referredEmailFieldVisible = idReferred === dataReferred?.find(referred => referred.isPrincipal === true)?.idReferred;
        setReferredEmailFieldVisible(referredEmailFieldVisible);
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                firstName: '',
                lastName: '',
                document: '',
                typeDocument: 'CédulaCiudadanía',
                email: '',
                phone: '',
                password: '',
                confirm_password: '',
                address: '',
                city: '',
                timeUpdate: '2020-06-22',
                biography: '',
                birthDate: '',
                idReferred: dataReferred ? dataReferred[0].idReferred : "",
                referredEmail: ''
            }}
            onSubmit={
                (values) => register(values)
            }
            validationSchema={
                Yup.object({
                    firstName: Yup.string()
                        .required('Este campo es obligatorio'),
                    lastName: Yup.string()
                        .required('Este campo es obligatorio'),
                    document: Yup.string()
                        .required('Este campo es obligatorio'),
                    typeDocument: Yup.string()
                        .required('Este campo es obligatorio'),
                    email: Yup.string()
                        .email('Escribe un formato de correo valido')
                        .required('Este campo es obligatorio'),
                    phone: Yup.string()
                        .min(7, 'Ingresa al menos 7 dígitos')
                        .max(10, 'Ingresa solo hasta 10 dígitos')
                        .required('Este campo es obligatorio'),
                    password: Yup.string()
                        .min(8, 'La clave debe ser de al menos 8 caracteres')
                        .matches(/[0-9]/, 'La clave debe tener al menos 1 número')
                        .matches(/[a-z]/, 'La clave debe tener al menos una letra minúscula')
                        .matches(/[A-Z]/, 'La clave debe tener al menos una letra mayúscula')
                        .required('Este campo es obligatorio'),
                    confirm_password: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
                        .required('Este campo es obligatorio'),
                    address: Yup.string()
                        .required('Este campo es obligatorio'),
                    city: Yup.string()
                        .required('Este campo es obligatorio'),
                    biography: Yup.string()
                        .required('Este campo es obligatorio'),
                    birthDate: Yup.string()
                        .required('Este campo es obligatorio'),
                    idReferred: Yup.string()
                        .required('Este campo es obligatorio'),
                    referredEmail: referredEmailFieldVisible ?
                        Yup.string()
                            .required('Este campo es obligatorio')
                            .email('Escribe un formato de correo valido')
                            .matches(/^[A-Za-z0-9._%+-]+@decathlon\.com$/, 'Este no es un correo Decathlon')
                        : Yup.string(),
                })
            }>
            {
                ({ setFieldValue, values }) => (
                    <Form onClick={() => handleValidateEmailField(values.idReferred)} className="px-8 space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto max-w-7xl mt-16">
                        <div className="space-y-8 divide-y divide-grey-dkt-200 sm:space-y-5">
                            <div className="space-y-6 sm:space-y-5">
                                <div>
                                    <h3 className="text-3xl leading-6 font-medium  text-dkt-brand-blue">INFORMACIÓN PERSONAL</h3>
                                    <p className="mt-1 text-lg">Asegúrate de que los datos que ingreses sean correctos para poder contactarte.</p>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="firstName" className="block text-lg font-medium">
                                            Nombres
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="firstName" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="lastName" className="block text-lg font-medium">
                                            Apellidos
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="lastName" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="typeDocument" className="block text-lg font-medium">
                                            Tipo de documento
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                as="select"
                                                id="typeDocument"
                                                name="typeDocument"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            >
                                                <option value={'CédulaCiudadanía'}>Cédula de ciudadanía</option>
                                                <option value={'CédulaExtranjería'}>Cédula de extranjería </option>
                                                <option value={'Pasaporte'}>Pasaporte</option>
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="document" className="block text-lg font-medium">
                                            Número de documento
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="document"
                                                id="document"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="document" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>

                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                        <label htmlFor="birthDate" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                            Fecha de nacimiento
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <Field
                                                type="date"
                                                id="birthDate"
                                                name="birthDate"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="birthDate" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                        <label htmlFor="email" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                            Correo electrónico
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <Field
                                                id="email"
                                                name="email"
                                                type="email"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="email" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                    <div className="sm:col-span-2 sm:pt-5">
                                        <label htmlFor="city" className="block text-lg font-medium">
                                            Ciudad
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="city"
                                                id="city"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="city" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2 sm:pt-5">
                                        <label htmlFor="address" className="block text-lg font-medium">
                                            Dirección
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="address"
                                                id="address"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="address" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2 sm:pt-5">
                                        <label htmlFor="phone" className="block text-lg font-medium">
                                            Teléfono
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="phone" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="password" className="block text-lg font-medium">
                                            Contraseña
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="password"
                                                name="password"
                                                id="password"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="password" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3 sm:pt-5">
                                        <label htmlFor="confirm_password" className="block text-lg font-medium">
                                            Confirmar contraseña
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                type="password"
                                                name="confirm_password"
                                                id="confirm_password"
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="confirm_password" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divide-y divide-grey-dkt-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

                                    <div >
                                        <h3 className="text-3xl leading-6 font-medium text-dkt-brand-blue">PERFIL</h3>
                                        <p className="mt-1 text-lg">Queremos saber más sobre ti</p>
                                    </div>
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                            <label htmlFor="idReferred" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                                ¿Eres referido?
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <Field
                                                    as="select"
                                                    id="idReferred"
                                                    name="idReferred"
                                                    className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                >
                                                    {dataReferred?.map((referred) => (
                                                        <option key={referred.idReferred} value={referred.idReferred}>{referred.name}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage name="idReferred" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                            </div>
                                            {dataReferred && dataReferred.length <= 0 &&
                                                <Alerts title={"Sin convenios"} content={"No hay convenios registrados en nuestro sistema."} />
                                            }
                                        </div>
                                        {referredEmailFieldVisible &&
                                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                                <label htmlFor="referredEmail" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                                    ¿Cómo es el correo electrónico de quien te refirió?
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <Field
                                                        type="email"
                                                        name="referredEmail"
                                                        id="referredEmail"
                                                        className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                    />
                                                    <ErrorMessage name="referredEmail" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-grey-dkt-200 sm:pt-5">
                                        <label htmlFor="biography" className="block text-lg font-medium sm:mt-px sm:pt-2">
                                            ¿Qué te motiva a trabajar en Decathlon?
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <Field
                                                as="textarea"
                                                id="biography"
                                                name="biography"
                                                rows={3}
                                                className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                            />
                                            <ErrorMessage name="biography" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sm:mb-8 sm:flex sm:justify-center pt-10">
                            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-dkt-brand-blue ring-1 ring-dkt-brand-blue hover:ring-gray-900/20 hover:bg-dkt-brand-blue hover:text-white">
                                <button className="font-semibold px-12">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    GUARDAR <span aria-hidden="true">&rarr;</span>
                                </button>
                            </div>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
}

export default RegisterForm