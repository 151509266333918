import { NavLink } from "react-router-dom"

export const VacancyBannerWd = () => {

    const inTheWorldImg = "/assets/images/v2/porQueNosotros/PorqueNosotros-ST_banner2.jpg"

    return (
        <section className="bg-dkt-bg-alternative">
            <div>
                <div className="relative isolate overflow-hidden pt-14">
                    <img
                        src={inTheWorldImg}
                        alt=""
                        className="absolute inset-0 -z-10 h-full w-full"
                    />
                    <div className="mx-auto max-w-2xl">
                        <div className="text-center  2xl:pt-24 xl:pt-24 lg:pt-24 md:pt-24 sm:pt-24 pt-6 pb-8">
                            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                ¿Decidido a hacer que las cosas sucedan?
                            </h1>
                        </div>
                        <div className="sm:mb-8 sm:flex justify-center text-center pt-8 pb-10">
                            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white ring-1 ring-white hover:ring-dkt-brand-blue hover:bg-dkt-brand-blue hover:text-white">
                                <NavLink to="/vacancies" className="font-semibold">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    VER NUESTRAS OFERTAS <span aria-hidden="true">&rarr;</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                        aria-hidden="true"
                    >
                    </div>
                </div>
            </div>
        </section>
    )
}