import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/bundle';
import 'swiper/css';
import './css/Carousel.css'

const carouselImages = [
    {
        key: 1,
        imageUrl: "/assets/images/v2/inicio/PaginaInicio-01.jpg",
        url: "aboutUs"
    },
    {
        key: 2,
        imageUrl: "/assets/images/v2/inicio/PaginaInicio-03.jpg",
        url: "whyWe"
    },
    {
        key: 3,
        imageUrl: "/assets/images/v2/inicio/PaginaInicio-02.jpg",
        url: "vacancies"
    },
    {
        key: 4,
        imageUrl: "/assets/images/v2/inicio/PaginaInicio-01.jpg",
        url: "aboutUs"
    },
    {
        key: 5,
        imageUrl: "/assets/images/v2/inicio/PaginaInicio-03.jpg",
        url: "whyWe"
    },
    {
        key: 6,
        imageUrl: "/assets/images/v2/inicio/PaginaInicio-02.jpg",
        url: "vacancies"
    }
]

export const Carousel = () => {
    return (
        <Swiper
            modules={[Navigation, A11y, Autoplay]}
            spaceBetween={0}
            slidesPerView={3}
            loop={true}
            effect={'coverflow'}
            centeredSlides={true}
            navigation
            autoplay={{
                delay: 5000,
                disableOnInteraction: false
            }}
            breakpoints={{
                640: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                }
            }}
        >
            <div className="flex items-center justify-center">
                {
                    carouselImages.map((images) => (
                        <SwiperSlide key={images.key} className="qwerty flex items-center justify-center">
                            <Link to={images.url}>
                                <img className="w-full h-full object-cover" src={images.imageUrl} alt="" />
                            </Link>
                        </SwiperSlide>
                    ))
                }
            </div>
        </Swiper>
    );
}

