import { Link, useNavigate } from 'react-router-dom'

export const NoAccess = () => {

    const navigate = useNavigate()
    return (
        <div className="bg-white min-h-full flex flex-col lg:relative">
            <div className="flex-grow flex flex-col">
                <main className="flex-grow flex flex-col bg-white">
                    <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
                        <div className="flex-shrink-0 pt-10 sm:pt-16">
                            <a href="/" className="inline-flex">
                                <span className="sr-only">Workflow</span>
                                <img
                                    className="h-12 w-auto"
                                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="flex-shrink-0 my-auto py-16 sm:py-32">
                            <h1 className="mt-2 text-4xl font-bold text-grey-dkt-900 tracking-tight sm:text-5xl sm:tracking-tight">
                                Sin acceso
                            </h1>
                            <p className="mt-2 text-base text-grey-dkt-500">Inicia sesión o regístrate para acceder a esta pagina</p>
                            <div className="mt-6">
                                <button type='button' onClick={() => navigate(-1)} className="text-base font-medium text-purple-dkt-600 hover:text-purple-dkt-500">
                                    Ir a la pagina anterior<span aria-hidden="true"> &rarr;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
                <footer className="flex-shrink-0 bg-grey-dkt-50">
                    <div className="mx-auto max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
                        <nav className="flex space-x-4">
                            <Link to="/login" className="text-sm font-medium text-grey-dkt-500 hover:text-grey-dkt-600">
                                Inicia sesión
                            </Link>
                            <span className="inline-block border-l border-grey-dkt-300" aria-hidden="true" />
                            <Link to="/register" className="text-sm font-medium text-grey-dkt-500 hover:text-grey-dkt-600">
                                Regístrate
                            </Link>
                        </nav>
                    </div>
                </footer>
            </div>
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
                    alt=""
                />
            </div>
        </div>
    )
}
