import { FlipCard } from '../../../ui/FlipCard'

export const WhyDecathlon = () => {

    const reasons = [
        {
            description: 'Todos en nuestro equipo compartimos una profunda pasión por el deporte. Nos dedicamos a mover a las personas y cambiar vidas a través de las maravillas del deporte. Si el deporte es tu pasión, encontrarás tu lugar en nuestro equipo.',
            imageUrl: "/assets/images/v2/inicio/PaginaInicio-11.jpg",
            title: "Pasión por el Deporte"
        },
        {
            description: 'Creemos en compartir el éxito con nuestros colaboradores. Ofrecemos la posibilidad de convertirte en accionista de la empresa, dándote la oportunidad de participar en nuestro crecimiento y éxito a largo plazo.',
            imageUrl: "/assets/images/v2/inicio/PaginaInicio-12.jpg",
            title: "Ser Accionista"
        },
        {
            description: 'Nos dedicamos al crecimiento continuo de nuestros colaboradores. En nuestra empresa, tendrás múltiples oportunidades para desarrollarte profesionalmente, adquirir nuevas habilidades y avanzar en tu carrera.',
            imageUrl: "/assets/images/v2/inicio/PaginaInicio-13.jpg",
            title: "Plan carrera"
        },
        {
            description: 'Trabajamos con un sentido que va más allá del éxito profesional. encontrarás proyectos apasionantes para impactar la comunidad y el medio ambiente, reflejando nuestro compromiso de mover a las personas a través de las maravillas del deporte.',
            imageUrl: "/assets/images/v2/inicio/PaginaInicio-14.jpg",
            title: "Compromiso"
        },
    ]

    return (
        <div className="bg-white pt-8">
            <div className="mx-auto">
                <div className="2xl:max-w-7xl xl:max-w-7xl lg:max-w-6xl md:max-w-4xl sm:max-w-2xl mx-auto lg:text-left px-4">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Únete a Nuestro Equipo
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        ¿Listo para entrar al campo y marcar la diferencia? En nuestro equipo, cada colaborador es un jugador clave.
                        Si te apasiona el deporte y buscas un lugar donde puedas crecer y contribuir, te estamos esperando. Aquí, jugamos en equipo, innovamos constantemente y siempre buscamos ganar juntos.
                        ¡Únete a nosotros y lleva tu carrera al siguiente nivel!
                    </p>
                </div>
                <div className="mx-auto mt-10">
                    <div>
                        <ul
                            role="list"
                            className="mx-auto grid lg:mx-0 lg:max-w-none bg-dkt-bg-alternative sm:grid-cols-2 lg:grid-cols-4"
                        >
                            {reasons.map((reason) => (
                                <li>
                                    <FlipCard title={reason.title} image={reason.imageUrl} content={reason.description} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}