import { ApplicantProfile } from "../interfaces/Applicant";
import { recluiterApi } from "../utils/axios";

interface RegisterResponse {
    token: string
}

interface PasswordResetResponse  {
    email:string
}

interface NewPasswordResponse  {
    newPass: string,
    pass: string
}

export const postRegister = (applicant: ApplicantProfile | undefined): Promise<RegisterResponse> => {
    return recluiterApi.post<RegisterResponse>(`/applicants/create`, applicant).then(res => res.data)
}

export const putRegister = (applicant: ApplicantProfile | undefined): Promise<RegisterResponse> => {
    return recluiterApi.put<RegisterResponse>(`/applicants/update`, applicant).then(res => res.data)
}

export const postPasswordReset = ({email}: PasswordResetResponse) => {
    return recluiterApi.post(`/applicants/password/reset/${email}`).then(res => res.data)
}

export const postNewPass = (pass: NewPasswordResponse | undefined): Promise<RegisterResponse> => {
    return recluiterApi.post<RegisterResponse>(`/applicants/newPassword`, pass).then(res => res.data)
}
