import { FlipCard } from "../../../ui/FlipCard"


const benefits = [
    {
        img: "/assets/images/v2/porQueNosotros/PorqueNosotros-ST-03.jpg",
        icon: "/assets/images/newlookandfeeltcn/Iconografia/Descuento-nuestros-articulos.png",
        title: "Descuentos en nuestros artículos",
        description: "La fuerza de Decathlon está en nuestros equipos. Tenemos una pasión colectiva por el deporte y eso es lo que nos motiva a desarrollar y vender productos técnicos a los mejores precios."
    },
    {
        img: "/assets/images/v2/porQueNosotros/PorqueNosotros-ST-06.jpg",
        icon: "/assets/images/newlookandfeeltcn/Iconografia/Planes-de-salud.png",
        title: "Planes de salud",
        description: "Los colaboradores de Decathlon tenemos acceso a planes de salud adicionales de acuerdo a nuestras necesidades."
    },
    {
        img: "/assets/images/v2/porQueNosotros/PorqueNosotros-ST-04.jpg",
        icon: "/assets/images/newlookandfeeltcn/Iconografia/Podemos-ser-accionistas-de-la-empresa.png",
        title: "Podemos ser accionistas de la empresa",
        description: "Cada empleado tiene la oportunidad de ser accionista de la empresa y tener los beneficios de la valorización de las acciones de la empresa anualmente."
    },
    {
        img: "/assets/images/v2/porQueNosotros/PorqueNosotros-ST-05.jpg",
        icon: "/assets/images/newlookandfeeltcn/Iconografia/Practicamos-deporte-juntos.png",
        title: "Practicamos deporte juntos",
        description: "Nosotros como colaboradores de Decathlon practicamos deporte juntos y contamos con diferentes beneficios qué incentiva nuestra práctica deportiva."
    }
]

const image = '/assets/images/decathlon-generica.jpeg'

export const Benefits = () => {
    return (
        <ul
            role="list"
            className="mx-auto grid lg:mx-0 lg:max-w-none bg-dkt-bg-alternative sm:grid-cols-2 lg:grid-cols-4"
        >
            {
                benefits.map((benefit) => (
                    <li>
                        <FlipCard title={benefit.title} image={benefit.img} content={benefit.description} />
                    </li>
                ))
            }

        </ul>
    )
}