import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import useSession from '../../../../hooks/useSession';
import { isSignedIn } from '../../../../utils/isSignedIn';
import { NoAccess } from '../../../../components/pages/landingPage/NoAccess';

export const AccountSettings = () => {

    const { currentUser } = useSession()
    const { newPass } = useSession()

    return (
        !isSignedIn() ? 
        <NoAccess />
        :
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: currentUser?.email,
                    pass: '',
                    newPass: '',
                    confirmPassword: ''
                }}
                onSubmit={
                    (values) => {
                        console.log(values)
                        const format = {
                            pass: values.pass,
                            newPass: values.newPass
                        }
                        newPass(format)
                    }
                }
                validationSchema={
                    Yup.object({
                        email: Yup.string()
                            .required('Este campo es obligatorio'),
                        pass: Yup.string()
                            .required('Este campo es obligatorio'),
                        newPass: Yup.string()
                            .min(8,'La clave debe ser de al menos 8 caracteres')
                            .matches(/[0-9]/, 'La clave debe tener al menos 1 número')
                            .matches(/[a-z]/, 'La clave debe tener al menos una letra minúscula')
                            .matches(/[A-Z]/, 'La clave debe tener al menos una letra mayúscula')
                            .required('Este campo es obligatorio'),
                        confirmPassword: Yup.string()
                            .oneOf([Yup.ref('newPass'), null], 'Las contraseñas no coinciden')
                            .required('Este campo es obligatorio')
                    })
                }>
                {
                    () => (
                        <Form className="space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto px-4 max-w-7xl sm:px-6 lg:px-8 mt-8">
                    <div className="space-y-8 divide-y divide-grey-dkt-200 sm:space-y-5">
                        <div className="divide-y divide-grey-dkt-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                            <div>
                                <h3 className="text-2xl leading-6 font-medium text-dkt-brand-blue">ADMINISTRA TU CUENTA</h3>
                                <p className="mt-1 max-w-2xl text-lg">Modifica tu contraseña o cierra tu cuenta por completo</p>
                            </div>

                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                <div className="sm:col-span-3 sm:pt-5">
                                    <label htmlFor="email" className="block text-lg font-medium">
                                        Correo electrónico
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="text"
                                            name="email"
                                            id="email"
                                            autoComplete="email"
                                            disabled
                                            className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                        />
                                        <ErrorMessage name="email" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                    </div>
                                </div>

                                <div className="sm:col-span-3 sm:pt-5">
                                    <label htmlFor="pass" className="block text-lg font-medium">
                                        Contraseña actual
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="password"
                                            name="pass"
                                            id="pass"
                                            autoComplete="pass"
                                            className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                        />
                                        <ErrorMessage name="pass" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:border-t sm:border-grey-dkt-200">
                                <div className="sm:col-span-3 sm:pt-5">
                                    <label htmlFor="newPass" className="block text-lg font-medium">
                                        Nueva contraseña
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="password"
                                            name="newPass"
                                            id="newPass"
                                            autoComplete="newPass"
                                            className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                        />
                                        <ErrorMessage name="newPass" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                    </div>
                                </div>

                                <div className="sm:col-span-3 sm:pt-5">
                                    <label htmlFor="confirmPassword" className="block text-lg font-medium">
                                        Confirmar nueva contraseña
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            autoComplete="confirmPassword"
                                            className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                        />
                                        <ErrorMessage name="confirmPassword" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-full text-dkt-green-01 bg-dkt-brand-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dkt-500"
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                </Form>
                    )
                }
            </Formik>


            <div className="space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto px-4 max-w-7xl sm:px-6 lg:px-8">
                <div className="bg-white">
                    <div>
                        <div>
                            <h3 className="text-2xl leading-6 font-medium text-dkt-brand-blue">BORRAR TU CUENTA</h3>
                            <p className="mt-1 max-w-2xl text-lg">Ten cuidado, está acción es definitiva</p>
                        </div>
                        <div className="mt-2 flex">
                            <div className="inline-flex rounded-md shadow">
                                <button
                                    className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-red-dkt-400 hover:bg-red-dkt-500"
                                >
                                    Borrar mi cuenta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="space-y-8 divide-y divide-grey-dkt-200 bg-white pb-6 mx-auto px-4 max-w-7xl sm:px-6 lg:px-8 pt-6">
                <div className="bg-white">
                    <div>
                        <div>
                            <h3 className="text-2xl leading-6 font-medium text-dkt-brand-blue">PRIVACIDAD</h3>
                        </div>
                        <div className="flex">
                            <div className="inline-flex">
                                <p className="mt-1 text-lg">Puedes consultar nuestra&nbsp;</p>
                                <Link
                                    to={`/privacy`}
                                    className="mt-1 text-lg text-dkt-green-01"
                                >
                                    política de privacidad.
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
