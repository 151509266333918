import { AboutUsContent } from "../../../../../interfaces/Contents";

interface Props {
    aboutUsContent?: AboutUsContent;
}

export const OurHistory = ({ aboutUsContent }: Props) => {
    return (
        <section className="bg-dkt-brand-blue">
            <div className="xl:max-w-7xl lg:max-w-6xl md:max-w-4xl sm:max-w-2xl mx-auto text-white py-12 px-4">
                <h1 className="text-5xl font-bold pb-6">Nuestra historia</h1>
                <h3 className="pb-6" dangerouslySetInnerHTML={{ __html: aboutUsContent?.aboutUs ?? '' }} />
                <h1 className="text-5xl font-bold pb-6">Decathlon en Colombia</h1>
                <h3 dangerouslySetInnerHTML={{ __html: aboutUsContent?.decathlonInColombia ?? '' }} />
            </div>
        </section>
    )
}