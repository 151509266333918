import { RegisterForm, RegisterFooter } from '.'

export const Register = () => {
    return (
        <>
            <RegisterForm />
            <RegisterFooter />
        </>
    )
}
