import { NavLink } from "react-router-dom"

export const VacancyBanner = () => {
    return (
        <section className="bg-dkt-bg-alternative">
            <div className="mx-auto xl:max-w-7xl lg:max-w-6xl md:max-w-4xl sm:max-w-2xl py-12 px-4">
                <div>
                    <h1 className="lg:text-5xl md:text-5xl sm:text-4xl xs:text-center font-bold tracking-tight text-dkt-brand-blue xl:px-44 lg:px-40 sm:px-0">
                        <div>Descubre nuestras <span className="text-dkt-green-01">vacantes</span> y </div>
                        <div className="text-left xl:pl-40 lg:pl-30 md:pl-0 sm:px-0 xs:text-center ">escoge la que mas te convenga</div>
                    </h1>
                </div>
                <div className="sm:mb-8 sm:flex sm:justify-center pt-10">
                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-dkt-brand-blue ring-1 ring-dkt-brand-blue hover:ring-gray-900/20 hover:bg-dkt-brand-blue hover:text-white">
                        <NavLink to="vacancies" className="font-semibold">
                            <span className="absolute inset-0" aria-hidden="true" />
                            VER NUESTRAS OFERTAS <span aria-hidden="true">&rarr;</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    )
}