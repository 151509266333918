import { Carousel } from "../../../ui/Carousel"
import { Footer } from "../../../ui/Footer"
import { Banner } from "./Banner"
import { VacancyBanner } from "./VacancyBanner"
import { WhyDecathlon } from "./WhyDecathlon"


export const HomePage = () => {

    return (
        <>

            <Carousel />

            <Banner />

            <WhyDecathlon />

            <VacancyBanner /> 

            <Footer />

        </>
    )
}