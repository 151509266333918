import {
    CalendarIcon,
    LocationMarkerIcon,
    XCircleIcon,
    LockClosedIcon
} from '@heroicons/react/solid'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { Applicantions } from '../../../../interfaces/Applications'
import { cancelApplication } from '../../../../selectors/applications'
import { HistoryMyNominations } from '.'


interface Props {
    applicant: Applicantions
    idx: any
}

export const ListMyNominations = ({ applicant, idx }: Props) => {

    const queryClient = useQueryClient()

    const { mutate: discardApplication } = useMutation(cancelApplication, {
        onSuccess: () => {
            queryClient.invalidateQueries(["myApplications"])
            toast.dismiss()
            toast.success("La candidatura a sido cancelada", { style: { background: "#3643BA", color: "white" } })
        },
        onError: ({ error }) => {
            console.log(error)
            toast.dismiss()
            toast.error("No fue posible cancelar la candidatura, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
        },
        onMutate: () => {
            toast.loading("Cancelando la candidatura.", { style: { background: "#7AFFA6", color: "white" } })
        }
    })

    return (
        <div className={"py-8 xl:py-10 " + (idx % 2 === 0 ? "bg-white" : "bg-grey-dkt-50")} >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:grid xl:grid-cols-3">
                <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-grey-dkt-300">
                    <div>
                        <div>
                            <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6 border-dkt-green-01">
                                <div>
                                    <h1 className="text-2xl font-bold">{applicant.Title}</h1>
                                </div>
                                {
                                    applicant.applicationstatus ?

                                        <div className="mt-4 flex space-x-3 md:mt-0">
                                            <button
                                                type="button"
                                                onClick={() => discardApplication(applicant.IdApplications)}
                                                className="inline-flex justify-center px-4 py-2 border border-grey-dkt-300 shadow-sm text-sm font-medium rounded-full text-white bg-dkt-brand-blue hover:text-dkt-green-01 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grey-dkt-900"
                                            >
                                                <XCircleIcon className="-ml-1 mr-2 h-5 w-5 text-dkt-green-01" aria-hidden="true" />
                                                <span>Cancelar candidatura</span>
                                            </button>
                                        </div> :
                                        <div className="mt-4 flex space-x-3 md:mt-0">
                                            <div
                                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-dkt-brand-blue rounded-full"
                                            >
                                                <LockClosedIcon className="-ml-1 mr-2 h-5 w-5 text-dkt-green-01" aria-hidden="true" />
                                                <span>Candidatura cancelada</span>
                                            </div>
                                        </div>
                                }
                            </div>
                            {/* {vista en moviles} */}
                            <aside className="mt-8 xl:hidden">
                                <h2 className="sr-only">Details</h2>
                                <div className="space-y-1">
                                    <div className="flex items-center space-x-2">
                                        <CalendarIcon className="h-5 w-5 text-grey-dkt-500" aria-hidden="true" />
                                        <span className="text-blue-dkt-400 text-lg font-medium">
                                            Candidatura creada en <time dateTime="2020-12-02">{applicant.positionDate}</time>
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <LocationMarkerIcon className="h-5 w-5 text-grey-dkt-500" aria-hidden="true" />
                                        <span className="text-lg font-medium text-blue-dkt-400">{applicant.place}</span>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                    <HistoryMyNominations historyApplicant={applicant.events} />
                </div>

                <aside className="hidden xl:block xl:pl-8">
                    <h2 className="sr-only">Details</h2>
                    <div className="space-y-1">
                        <div className="flex items-center space-x-2">
                            <CalendarIcon className="h-5 w-5 text-dkt-brand-blue" aria-hidden="true" />
                            <span className="text-grey-dkt-400 text-lg font-medium">
                                Candidatura creada en <time dateTime="2020-12-02">{applicant.positionDate}</time>
                            </span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <LocationMarkerIcon className="h-5 w-5 text-dkt-brand-blue" aria-hidden="true" />
                            <span className="text-lg font-medium text-grey-dkt-400">{applicant.place}</span>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    )
}
