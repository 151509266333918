import { Applicantions } from "../interfaces/Applications"
import { recluiterApi } from "../utils/axios"

export const getApplicantionsHistoric = (id:string | undefined): Promise<Array<Applicantions>> => {
    return recluiterApi.get<Array<Applicantions>>(`/applications/historic/${id}`).then(res => res.data)
}

export const postApplicantionsCreate = (applications: any) => {
    return recluiterApi.post(`/applications/create`, applications).then(res => res.data)
}

export const cancelApplication = (id: string) => {
    return recluiterApi.patch(`/applications/cancelApplication/${id}`).then(res => res.data)
}
