import { useNavigate } from "react-router-dom";
import useSession from "../../../hooks/useSession";
import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ReCAPTCHA from "react-google-recaptcha";
import { postRecaptcha } from "../../../selectors/login";
import toast from 'react-hot-toast'
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup'
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { ModalRecoverPassword } from "../../../components/auth/ModalRecoverPassword";
import { Alerts } from "../../../components/ui/Alerts";

export const Login = () => {

    const { login, loadingLogin, errorLogin } = useSession()
    const navigate = useNavigate();
    const [shown, setShown] = useState(false);
    const switchShown = () => setShown(!shown);
    const [openModal, setOpenModal] = useState(false)
    const [validCaptcha, setValidCaptcha] = useState({
        score: 0,
        isValid: false
    })
    const queryClient = useQueryClient()

    const recaptchaRef = useRef<ReCAPTCHA>(null)

    const { mutate: recaptcha } = useMutation(postRecaptcha, {
        onSuccess: (response) => {
            setValidCaptcha({
                score: response.riskAnalysis.score,
                isValid: response.tokenProperties.valid
            })
            queryClient.invalidateQueries(["recaptcha"])
        },
        onError: ({ error }) => {
            toast.dismiss()
            toast.error("Recaptcha invalido.", { style: { background: "#E3262F", color: "white" } })
        }
    })

    const onChange = (value: any) => {
        recaptcha(
            {
                event: {
                    token: value,
                    expectedAction: "login",
                    siteKey: process.env.REACT_APP_SITEKEY
                }
            }
        )
    }

    return (
        <>
            <div className="flex min-h-full flex-1 shadow-inner">
                <div className="flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Conéctate a tu cuenta
                            </h2>
                        </div>

                        <div className="mt-10">
                            <div>
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                    }}
                                    onSubmit={
                                        (values) => {
                                            if (recaptchaRef.current?.getValue()) {
                                                if (validCaptcha.score >= 0.5 && validCaptcha.isValid === true) {
                                                    recaptchaRef.current.reset()
                                                    login(values)
                                                } else {
                                                    toast.error('Debes marcar de nuevo la casilla de "No soy un robot"', { style: { background: "#E3262F", color: "white" } })
                                                    recaptchaRef.current.reset()
                                                }
                                            } else {
                                                toast.error('Debes marcar la casilla de "No soy un robot"', { style: { background: "#E3262F", color: "white" } })
                                            }
                                        }
                                    }
                                    validationSchema={
                                        Yup.object({
                                            email: Yup.string()
                                                .email('Escribe un formato de correo valido')
                                                .required('Este campo es obligatorio'),
                                            password: Yup.string()
                                                .required('Este campo es obligatorio'),
                                        })
                                    }>
                                    {
                                        () => (
                                            <Form>
                                                <div>
                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Correo electrónico
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                        />
                                                        <ErrorMessage name="email" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Contraseña
                                                    </label>
                                                    <div className="mt-2 relative">
                                                        <Field
                                                            id="password"
                                                            name="password"
                                                            type={shown ? 'text' : 'password'}
                                                            className="focus:border-grey-dkt-100 block w-full sm:text-sm border-grey-dkt-300 rounded-sm"
                                                        />
                                                        <button type="button" onClick={switchShown} className="absolute inset-y-0 right-0 pr-3 flex items-center h-10">
                                                            {shown ? <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> : <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                                        </button>
                                                        <ErrorMessage name="password" render={msg => <p className="mt-1 text-sm text-dkt-green-01">{msg}</p>} />
                                                    </div>
                                                </div>

                                                <div className="flex items-center justify-between py-6">
                                                    <div className="text-sm">
                                                        <button type="button" onClick={() => setOpenModal(true)} className="font-medium text-grey-dkt-900 hover:text-grey-dkt-400">
                                                            Olvidaste tu contraseña?
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className='justify-center grid'>
                                                    <ReCAPTCHA
                                                        ref={recaptchaRef}
                                                        hl='es'
                                                        sitekey="6LcG--kpAAAAACgtcyM6QOCMiwW2tlpErTtUmNdy"
                                                        onChange={onChange}
                                                    />
                                                </div>

                                                <ModalRecoverPassword open={openModal} setOpen={setOpenModal} />

                                                {errorLogin && <Alerts title={'Error en credenciales'} content={'Tu usuario o contraseña son incorrectos, vuelve a intentar'}></Alerts>}
                                                {loadingLogin && <Alerts title={'Validando credenciales'} content={'Se estan validando los datos ingresados...'}></Alerts>}

                                                <div className="sm:mb-8 sm:flex sm:justify-center pt-8">
                                                    <div className="flex relative w-full px-3 py-1.5 justify-center  rounded-full text-sm leading-6 text-dkt-brand-blue ring-1 ring-dkt-brand-blue hover:ring-gray-900/20 hover:bg-dkt-brand-blue hover:text-white">
                                                        <button className="font-semibold" type="submit">
                                                            <span className="absolute inset-0" aria-hidden="true" />
                                                            Ingresar <span aria-hidden="true">&rarr;</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }
                                </Formik>
                            </div>

                            <div className="mt-10">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-200" />
                                    </div>
                                    <div className="relative flex justify-center text-sm font-medium leading-6">
                                        <span className="bg-white px-6 text-gray-900">¿No tienes cuenta?</span>
                                    </div>
                                </div>

                                <div className="mt-6 grid gap-3">
                                    <div>
                                        <button
                                            onClick={() => { navigate("/register") }}
                                            type="submit"
                                            className="flex relative w-full px-3 py-1.5 justify-center  rounded-full text-sm leading-6 text-dkt-green-01 ring-1 ring-dkt-brand-blue hover:ring-gray-900/20 bg-dkt-brand-blue hover:text-white"
                                        >
                                            <span className="absolute inset-0" aria-hidden="true" />
                                            Regístrate <span aria-hidden="true">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 w-full object-cover"
                        src="/assets/images/v2/login.jpg"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}